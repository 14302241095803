<template>
  <div class="vip_warp" v-loading="showLoading">
    <!--抵扣包信息-->
    <div class="dif alc" style="margin-bottom: 49px;">
      <div class="fs32 col000">
        <p>抵扣包</p>
        <p style="  margin-top: 18px;">剩余{{userInfo.dk_number}}次</p>
      </div>
    </div>

    <!--会员开通列表-->
    <div class="dif alc">
      <div class="vip_item" @click="current = index"
           :class="{'active': current===index}" v-for="(item,index) in list" :key="index">
        <div class="tct vip_title" :class="{'active': current===index}">{{item.name}}</div>
        <div class="vip_price " style="padding-top: 15px;" :class="{'active': current===index}">
          <span style="font-size: 29px;margin-right: 6px;">￥</span>
          <span style="font-size: 29px;" class="bold">{{item.price}}</span>
        </div>
        <div class="vip_price pdTop10 pdBtm30" :class="{'active': current===index}">
          <span style="font-size: 28px;" class="bold">{{item.number}}次</span>
        </div>
      </div>
    </div>
    <!--抵扣包介绍-->
    <div class="col000" v-if="list.length>0">
      <p class="fs24" style="margin-top: 24px;" v-html="list[current].introduce"></p>
    </div>
    <!--立即开通按钮-->
    <div class="difcac colfff submit_button" @click="showPay=true">立即购买</div>

    <!--支付方式选择-->
    <el-dialog
        title="选择支付方式"
        :visible.sync="showPay"
        width="40%"
        top="25vh">
      <div>
        <div class="difcac">
          <div class="pay_item difcac" @click="payType=1" :class="{'active': payType===1}">
            <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">支付宝支付</span>
            <div class="check" v-if="payType===1"></div>
            <i class="el-icon-check check_icon" v-if="payType===1"></i>
          </div>
          <div class="pay_item difcac mrLft20" @click="payType=2" :class="{'active': payType===2}">
            <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">微信支付</span>
            <div class="check" v-if="payType===2"></div>
            <i class="el-icon-check check_icon" v-if="payType===2"></i>
          </div>
        </div>
        <div class="dife alc mrTop30">
          <el-button @click="showPay=false">取 消</el-button>
          <el-button type="primary" @click="payDeduction()">支 付</el-button>
        </div>
      </div>
    </el-dialog>
    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "deduction",
  data() {
    return {
      userInfo: {
        avatar: '',
        is_vip: 0,
        nickname: '',
        vip_end_time_str: '',//vip到期时间
        dk_number: 0,//抵扣包剩余次数
      },
      showLoading: false,
      list: [],//套餐列表
      current: 0,//当前选中的套餐
      content: '',//会员特权介绍
      showPay: false,
      payType: 1,//1支付宝支付 2微信支付
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
    }
  },
  methods: {
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
      this.$router.go(0)
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.go(0)
    },
    paySuccess() { //点击支付成功
      this.$router.go(0)
    },
    //vip下单
    payDeduction() {
      let data = {
        id: this.list[this.current].id,
        pay_type: this.payType
      }
      this.showPay = false
      this.$axios("POST","/deduction/place",data)
          .then(res => {
            if (res.code===1) {
              if(data.pay_type===1) {
                this.showAlipay = true
                // 打开支付宝form表单
                const div = document.createElement('div')
                div.innerHTML = res.data
                document.body.appendChild(div)
                document.forms[0].acceptCharset = "UTF-8"
                document.forms[0].target = "_blank"
                document.forms[0].submit()
              }else {
                this.showWeChatCode = true
                this.imgUrl = res.data
              }
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //获取会员特权信息
    getContent() {
      this.$axios("POST","/index/getConfig",{name: 'vip_info_jieshao'})
          .then(res => {
            if(res.code===1) this.content = res.data
          })
    },
    // 获取套餐列表
    getList() {
      this.showLoading = true
      this.$axios("POST","/deduction/getCombo",{})
          .then(res => {
            this.showLoading = false
            if(res.code===1) {
              this.list = res.data
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //获取用户信息
    getUserInfo() {
      if(localStorage.getItem('hbInfo')) {
        this.userInfo = JSON.parse(localStorage.getItem('hbInfo'))
      }
      this.$axios("POST","/user/getUserInfo",{})
          .then(res => {
            if(res.code===1) {
              localStorage.setItem('hbInfo',JSON.stringify(res.data))
              this.$store.commit('setUserInfo',res.data)
              this.userInfo = res.data
            }else {
              localStorage.removeItem('hbzg')
              this.$alert('获取用户信息失败，请刷新重试',{type: 'error'})
            }
          })
    },
  },
  created() {
    this.getUserInfo() //获取用户信息
    this.getList() //获取套餐列表
    this.getContent() //获取会员特权信息
  }
}
</script>

<style scoped>
.vip_item{
  cursor: pointer;
  width: 197px;
  height: 184px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  border: 3px solid #3EA6FF;
  box-sizing: border-box;
  margin-right: 27px;
  overflow: hidden;
}
.vip_item.active{
  border: none;
}
.vip_title{
  padding: 11px 0;
  font-size: 29px;
  color: #414141;
  border-bottom: 3px solid #F0F0F0;
  transition: all 0.3s
}
.vip_title.active{
  background-color: #1572F9;
  color: #ffffff;
  border-bottom: 3px solid #1572F9;
}
.vip_price{
  transition: all 0.3s;
  /*padding-top: 22px;*/
  /*padding-bottom: 40px;*/
  text-align: center;
  color: #F75C5C;
}
.vip_price.active{
  background-color: #3EA6FF;
  color: #ffffff;
}
.avatar{
  width: 140px;
  height: 140px;
  background: #EEEEEE;
  border-radius: 50%;
  margin-right: 44px;
}
.vip_warp{
  width: 1114px;
  height: 803px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 37px;
  padding-left: 46px;
}
.submit_button{
  cursor: pointer;
  transition: all 0.3s;
  width: 443px;
  height: 65px;
  background: #007AFF;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  color: #ffffff;
  margin-top: 36px;
}
.submit_button:hover{
  background-color: #3765FF;
}
.pay_item{
  width: 236px;
  height: 69px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #868686;
  cursor: pointer;
  font-size: 28px;
  color: #383D42;
  overflow: hidden;
  position: relative;
}
.pay_item.active{
  border: 2px solid #337DF7;
  color: #337DF7;
}
.check{
  width: 41px;
  height: 41px;
  background-color: #3765FF;
  position: absolute;
  right: -20px;
  bottom: -20px;
  transform: rotate(45deg);
}
.check_icon{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  color: #ffffff;
  z-index: 22;
}
</style>