<template>
  <div>
    <!--内容-->
    <div style="width: 1200px;margin: 30px auto;">
      <!--分类-->
      <div class="difb">
        <div>
          <el-tabs v-model="activeName" @tab-click="changeElTabs">
            <el-tab-pane v-for="(item,index) in cates" :key="index"
                         :label="item.name" :name="item.id"></el-tab-pane>
          </el-tabs>
        </div>
        <p class="pointer hover fs28" style="color: #1572F9" v-if="!isUpdata" @click="isUpdata=!isUpdata">管理</p>
        <p class="pointer hover fs28" style="color: #1572F9" v-else @click="isUpdata=!isUpdata">完成</p>
      </div>
      <!--工程设备-->
      <div v-if="currentActive==0">
        <div class="wtb_warp" v-if="cates[0].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[0].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <div class="difb alc" style="margin-top: 52px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
      <!--安装工程-->
      <div v-if="currentActive==1">
        <div class="wtb_warp" v-if="cates[1].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[1].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <div class="difb alc" style="margin-top: 52px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
      <!--物流货运-->
      <div v-if="currentActive==2">
        <div class="wtb_warp" v-if="cates[2].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[2].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <!--内容-->
            <p class="fs14 mrTop10 hover pointer" style="color: #868686;line-height: 1.4em;">
              {{item.describe}}
            </p>
            <div class="difb alc" style="margin-top: 52px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
      <!--人才招聘-->
      <div v-if="currentActive==3">
        <div class="wtb_warp" v-if="cates[3].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[3].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <!--内容-->
            <div style="margin: 13px 0;">
              <span style="color: #FB6124" class="fs18">{{item.post_salary_text}}</span>
              <!--<span style="color: #868686" class="fs14">元/月</span>-->
              <span style="color: #FB6124;margin-left: 13px;" class="fs18">{{item.work_experience_text}}</span>
            </div>
            <div class="difb alc" style="margin-top: 32px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
      <!--求职信息-->
      <div v-if="currentActive==4">
        <div class="wtb_warp" v-if="cates[4].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[4].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <!--内容-->
            <div style="margin: 13px 0;">
              <span style="color: #FB6124" class="fs18">{{item.post_salary_text}}</span>
              <!--<span style="color: #868686" class="fs14">元/月</span>-->
              <span style="color: #FB6124;margin-left: 13px;" class="fs18">{{item.work_experience_text}}</span>
            </div>
            <div class="difb alc" style="margin-top: 32px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
      <!--二手设备-->
      <div v-if="currentActive==5">
        <div class="wtb_warp" v-if="cates[5].list.length>0">
          <div class="wtb_item pointer" v-for="(item,index) in cates[5].list" :key="index" @click="goDetail(item)">
            <div class="difb alc">
              <div class="dif alc">
                <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
                <!--<p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>-->
              </div>
              <p style="color: #fa3534" class="fs18 bold" @click.stop="cancel(item)" v-if="isUpdata">取消收藏</p>
            </div>
            <div class="difb alc" style="margin-top: 52px;">
              <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
              <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
            </div>
          </div>
          <!--分页-->
          <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-count="allPage"
                :current-page="page"
                @current-change="changeSize">
            </el-pagination>
          </div>
        </div>
        <div v-else style="padding: 100px 0;">
          <el-result icon="info" title="提示" subTitle="暂无收藏"></el-result>
        </div>
      </div>
    </div>

    <!--弹窗提示-->
    <transition enter-active-class="animation-scale-up" leave-active-class="animation-scale-up reverse">
      <div class="pay_bg difcac" v-if="showPay" @click="showPay=false">
        <div class="pay_warp" @click.stop>
          <p class="tct fs32 col000">提示</p>
          <p class="fs32 tct" style="color: #414141;margin-top: 29px;">您需要消耗<span style="color: #F75C5C">2元</span>，才可以继续浏览</p>
          <div class="difcac" style="margin-top: 56px;">
            <div class="button1 difcac" @click="showPay=false">取消</div>
            <div class="button2 difcac" @click="goPay()">确定</div>
          </div>
          <!--<p class="tct fs24 col000" style="margin-top: 42px;">-->
          <!--  <span style="color: #FB6124" class="hover pointer" @click="goVip()">加入VIP</span>,免费查看所有内容-->
          <!--</p>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
  name: "WantToBuy",
  components: {Navbar},
  data() {
    return {
      isUpdata: false,//是否边际
      showPay: false,//显示支付
      cates: [
        {id: '1',name: '求购设备',list: []},
        {id: '2',name: '安装工程',list: []},
        {id: '3',name: '物流货运',list: []},
        {id: '4',name: '人才招聘',list: []},
        {id: '5',name: '求职信息',list: []},
        {id: '6',name: '二手设备',list: []},
      ],//分类
      list: [1,2,3,],
      activeName: '1',
      currentActive: 0,

      isLoading: false,

      limit: 4,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数

    }
  },
  methods: {
    //取消收藏
    cancel(item) {
      this.isLoading = true
      this.$axios("POST","/common/postCollect",{
        post_id: item.id,
        type: this.activeName,
        collect_type: 2
      }).then(res => {
        if(res.code===1) {
          this.$message.success('取消成功')
          this.getList()
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    goDetail(item) { //跳转到详情
      if(this.isUpdata) return;
      let url = ''
      if(this.activeName==1) url = '/wangtobuy/detail'//工程设备
      if(this.activeName==2) url = '/insworks/detail'//安装工程
      if(this.activeName==3) url = '/logistics/detail'//物流货运
      if(this.activeName==4) url = '/recruit/detail'//人才招聘
      if(this.activeName==5) url = '/jobwanted/detail'//求职信息
      if(this.activeName==6) url = '/secondhand/detail'//二手设备
      let path = this.$router.resolve({
        path: url,
        query: {id: item.id},
      })
      window.open(path.href)
    },
    //选择发布类型
    changeElTabs(tab,event) {
      this.page = 1
      this.activeName = tab.name
      this.currentActive = this.cates.findIndex(item => item.id==tab.name)
      this.getList()
    },
    // 获取我的发布
    getList() {
      let data = {
        page: this.page,
        limit: this.limit,
        is_collect: 1
      }
      let current = this.currentActive
      let url = '/post/ask_buy/getList' //工程设备列表
      if(this.currentActive===1) url = '/post/information/getList' //安装工程列表
      if(this.currentActive===2) url = '/post/post_logistics_freight/getList' //物流货运列表
      if(this.currentActive===3) url = '/post/job_fair/getList' //人才招聘列表
      if(this.currentActive===4) url = '/post/job_intention/getList' //求职信息列表
      if(this.currentActive===5) url = '/post/second_hand/getList' //二手设备列表
      this.isLoading = true
      this.$axios("POST",url,data)
          .then(res => {
            this.isLoading = false
            if(res.code===1) {
              this.cates[current].list = res.data.list
              this.allPage = Math.ceil(res.data.total/this.limit)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getList();//获取工程设备求购列表
    },

    goPay() { //确认支付
      this.$router.push('/submitpay')
    },
    goVip() { //开通会员

    },
  },
  created() {
    this.getList() //获取收藏列表
  }
}
</script>

<style scoped>
/*分类*/
::v-deep .el-tabs__item{
  font-size: 28px;
  width: 150px;
  text-align: center;
}
::v-deep .el-tabs__nav-scroll{
  padding-bottom: 30px;
}
::v-deep .el-tabs__nav-next .el-icon-arrow-right{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__item.is-active{
  color: #1572F9;
}
::v-deep .el-tabs__active-bar{
  bottom: -30px;
  height: 6px;
  background-color: #1572F9;
}
::v-deep .el-tabs__active-bar:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 20px solid #1572F9;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-100%);
}
::v-deep .el-tabs__nav-wrap::after{
  height: 6px;
}
.wtb_warp{
  box-sizing: border-box;
  padding: 0 23px;
}
.wtb_item{
  border-bottom: 1px solid #F2F2F2;
  padding-top: 27px;
  padding-bottom: 45px;
}

.buy_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}

.pay_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 333;
  background-color: rgba(0,0,0,0.5);
}
.pay_warp{
  width: 602px;
  height: 389px;
  background: #FFFFFF;
  border-radius: 26px 26px 26px 26px;
  box-sizing: border-box;
  padding-top: 46px;
}
.button1{
  width: 216px;
  height: 73px;
  background: #DCDCDC;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 40px;
  transition: all 0.2s;
}
.button1:hover{
  background-color: #c4c4c4;
}
.button2{
  border-radius: 40px;
  width: 216px;
  height: 73px;
  background: #1572F9;
  font-size: 36px;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;
  transition: all 0.2s;
}
.button2:hover{
  background: #0152d0;
}
</style>