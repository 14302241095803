<template>
  <div class="difb">
    <!--左侧大图片-->
    <div class="login_left_bg posr">
      <img src="@/assets/images/icon_dlbj.png">
    </div>
    <!--右侧登录内容-->
    <div class="difcc posr" style="width: 35vw;">
      <!--返回首页-->
      <div class="dif alc homego" @click="goHome()">
        <p>返回首页</p>
        <i class="el-icon-arrow-right" style="color: #666666; font-size: 16px;"></i>
      </div>
      <!--登录框-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa"  v-if="current==0">
          <div style="width: 30.94rem;margin-bottom: 2.19rem;border-bottom: 1px solid #888888;padding-bottom: 0.5rem;" class="difb alc">
            <span style="font-size: 42px;color: #1572F9" class="bold">用户登录</span>
            <span style="font-size: 1.75rem;padding-top: 8px;" class="bold">
            <span style="color: #414141">没有账号？</span>
            <span class="pointer hover1" style="color: #1572F9" @click="current=1">立即注册</span>
          </span>
          </div>
          <!--登录方式选择-->
          <div class="difb alc" style="width: 27.63rem;border-bottom: 1px solid #888888;padding-bottom: 1rem;">
            <div class="login_tab" @click="login_current=0" :class="{'active': login_current==0}">普通登录</div>
            <div style="width: 1px;height: 30px;background-color: #888888"></div>
            <div class="login_tab" @click="login_current=1" :class="{'active': login_current==1}">手机号快速登录</div>
          </div>
          <!--普通登录-->
          <div>
            <div class="input_warp dif alc">
              <div style="width: 20%;" class="fs18 col333">手机号：</div>
              <div style="width: 80%;">
                <el-input v-model="login_obj.account" maxlength="11" placeholder="请输入手机号"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc" v-show="login_current==0">
              <div style="width: 20%;" class="fs18 col333">密码：</div>
              <div style="width: 80%;">
                <el-input v-model="login_obj.password" placeholder="请输入密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp posr dif alc" v-show="login_current==1">
              <div style="width: 20%;" class="fs18 col333">验证码：</div>
              <div class="posr" style="width: 80%;">
                <span class="getCode hover">
                <span v-if="time===31" @click="getCode(1)">获取验证码</span>
                <span v-else>{{time}}秒后重试</span>
              </span>
                <el-input v-model="login_obj.captcha" maxlength="11" placeholder="请输入验证码"></el-input>
              </div>
            </div>
            <div class="input_warp trt fz18 col000" style="margin-top: 19px;margin-bottom: 35px;">
              <span class="pointer hover" style="color: #1572F9" @click="current=3">忘记密码？</span>
            </div>
            <div class="login_button difcac" @click="login()">登录</div>
          </div>
        </div>
      </transition>
      <!--注册框-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa" v-if="current==1">
          <div style="width: 30.94rem;margin-bottom: 1.19rem;padding-bottom: 0.5rem;border-bottom: 1px solid #888888;" class="difb alc">
            <span style="font-size: 42px;color: #1572F9" class="bold">立即注册</span>
            <span style="font-size: 1.75rem;padding-top: 8px;" class="bold">
            <span style="color: #414141">有账号？</span>
            <span class="pointer hover" style="color: #1572F9" @click="current=0">去登录</span>
          </span>
          </div>
          <!--注册-->
          <div>
            <div class="input_warp dif alc">
              <div style="width: 25%;" class="fs20 col333">
                <span class="text-red">*</span>
                手机号：
              </div>
              <div style="width: 75%;">
                <el-input v-model="register_obj.username" maxlength="11" placeholder="请输入手机号"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc posr">
              <div class="fs20 col333 " style="width: 25%;">
                <span class="text-red">*</span>
                验证码：
              </div>
              <div class=" posr" style="width: 75%;">
                <span class="getCode hover">
                <span v-if="time===31" @click="getCode(2)">获取验证码</span>
                <span v-else>{{time}}秒后重试</span>
              </span>
                <el-input v-model="register_obj.code" maxlength="11" placeholder="请输入验证码"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="fs20 col333" style="width: 25%;">
                <span class="text-red">*</span>
                密码：</div>
              <div style="width: 75%;">
                <el-input v-model="register_obj.password" placeholder="请输入密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="fs20 col333" style="width: 30%;">
                <span class="text-red">*</span>
                确认密码：</div>
              <div style="width: 80%;">
                <el-input v-model="register_obj.re_password" placeholder="请确认密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="fs20 col333" style="width: 30%;">
                <span class="text-red">*</span>
                行业分类：</div>
              <el-select v-model="register_obj.industry_id" style="width: 70%;" placeholder="请选择行业分类">
                <el-option v-for="item of cates" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </div>
            <div class="did alc mrTop20 mrBtm30 dif alc">
              <img src="@/assets/images/03no_check.png" v-if="!isAgree" class="checked" @click="isAgree=!isAgree">
              <img src="@/assets/images/03yes_check.png" v-else class="checked" @click="isAgree=!isAgree">
              <span class="mrLft10">
                <span>我已阅读并同意</span>
                <span class="pointer hover fz18" style="color: #1572F9" @click="goXieyi()">《用户协议》</span>
              </span>
            </div>
            <div class="login_button difcac" :class="{'gray': !isAgree}" @click="register()">注册</div>
          </div>
        </div >
      </transition>
      <!--找回密码-->
      <transition enter-active-class="animation-scale-up">
        <div class="difcc posa" v-if="current==3">
          <div style="width: 30.94rem;margin-bottom: 1.19rem;border-bottom: 1px solid #888888;padding-bottom: 0.5rem;" class="difb alc">
            <span style="font-size: 42px;color: #1572F9" class="bold">找回密码</span>
            <span style="font-size: 1.75rem;padding-top: 8px;" class="bold">
            <span class="pointer hover" style="color: #1572F9" @click="current=0">去登录</span>
          </span>
          </div>
          <!--找回密码-->
          <div>
            <div class="input_warp dif alc">
              <div class="w20 col333 fs20">手机号：</div>
              <div class="w80">
                <el-input v-model="forget_obj.mobile" placeholder="请输入手机号"></el-input>
              </div>
            </div>
            <div class="input_warp posr dif alc">
              <div class="w20 col333 fs20">验证码：</div>
              <div class="w80 posr">
                <span class="getCode hover">
                <span v-if="time===31" @click="getCode(3)">获取验证码</span>
                <span v-else>{{time}}秒后重试</span>
              </span>
                <el-input v-model="forget_obj.captcha" maxlength="11" placeholder="请输入验证码"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="w20 col333 fs20">密码：</div>
              <div class="w80">
                <el-input v-model="forget_obj.newpassword" placeholder="请输入新密码" type="password"></el-input>
              </div>
            </div>
            <div class="input_warp dif alc">
              <div class="col333 fs20" style="width: 25%;">确认密码：</div>
              <div style="width: 75%;">
                <el-input v-model="forget_obj.newpassword2" placeholder="请确认密码" type="password"></el-input>
              </div>
            </div>
            <div class="login_button difcac" style="margin-top: 30px;" @click="forget()">找回密码</div>
          </div>
        </div >
      </transition>
    </div>

    <el-dialog :visible.sync="showContent"  center title="用户协议">
      <span>内容</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      current: -1,// 0登录 1注册 2忘记密码
      login_current: 0,//0 普通登录 1手机号快速登录
      login_obj: { //登录
        account: '',
        password: '',
        captcha: '',
      },
      register_obj: { //注册
        username: '',//手机号
        password: '',//密码
        re_password: '',//确认密码
        code: '',//验证码
        industry_id: '',//行业id
      },

      forget_obj: { //找回密码
        mobile: '',
        captcha: '',
        newpassword: '',//新密码
        newpassword2: '',//重复密码
      },
      isAgree: false,//是否同意协议
      showContent: false,//显示协议内容
      cates: [],//行业分类
      //验证码计时器
      time: 31,
      timeout: null,
    }
  },
  methods: {
    goXieyi() { //用户协议
      window.open('http://share.huizukeji.cn/index/index/text?id=164','_blank')
    },
    //返回首页
    goHome() {
      this.$router.push('/')
    },
    //获取行业分类
    getCates() {
      this.$axios("POST","/index/getIndustry",{})
      .then(res => {
        if(res.code===1) this.cates = res.data
      })
    },
    //获取验证码 1登录 2注册 3忘记密码
    getCode(type) {
      let data = {
        mobile: this.login_obj.account,
        event: 'mobilelogin'
      }
      if(type===2) {
        data.mobile = this.register_obj.username
        data.event = 'register'
      }else if(type===3) {
        data.mobile = this.forget_obj.mobile
        data.event = 'changepwd'
      }
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }
      clearInterval(this.timeout)
      this.$axios("POST","/sms/send",data,'发送中')
      .then(res => {
        if(res.code===1) {
          this.timeout = setInterval(()=>{
            this.time--
            if(this.time<=0) {
              this.time = 31
              clearInterval(this.timeout)
            }
          },1000)
          this.$message.success('发送成功')
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //找回密码
    forget() {
      let data = this.forget_obj
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }else if(data.captcha==='') {
        return this.$alert('请输入验证码',{type: 'error'})
      }else if(data.newpassword==='') {
        return this.$alert('请输入密码',{type: 'error'})
      }else if(data.newpassword2==='') {
        return this.$alert('请确认密码',{type: 'error'})
      }else if(data.newpassword2!==data.newpassword) {
        return this.$alert('两次输入密码不一致',{type: 'error'})
      }
      this.$axios("POST","/user/changepwd",data,'加载中')
      .then(res => {
        if(res.code===1) {
          this.$alert('修改密码成功',{type: 'success'})
          .then(con => this.current = 0)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //注册
    register() {
      let data = this.register_obj
      if(data.username==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }else if(data.code==='') {
        return this.$alert('请输入验证码',{type: 'error'})
      }else if(data.password==='') {
        return this.$alert('请输入密码',{type: 'error'})
      }else if(data.industry_id==='') {
        return this.$alert('请选择行业分类',{type: 'error'})
      }else if(!this.isAgree) {
        return this.$alert('请先阅读并同意协议内容',{type: 'error'})
      }
      this.$axios("POST","/user/register",data,'注册中')
      .then(res => {
        if(res.code===1) {
          this.login_obj.account = data.username
          this.$alert('注册成功',{type: 'success'})
          .then(con => this.current = 0)
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //登录
    login() {
      let data = {}
      let url = '/user/login'
      if(data.mobile==='') {
        return this.$alert('请输入手机号',{type: 'error'})
      }
      if(this.login_current===0) { //普通的登录
        data.account = this.login_obj.account
        data.password = this.login_obj.password
        if(data.password==='') {
          return this.$alert('请输入密码',{type: 'error'})
        }
      }else { //验证码登录
        url = '/user/mobilelogin'
        data.captcha = this.login_obj.captcha
        data.mobile = this.login_obj.account
        if(data.captcha==='') {
          return this.$alert('请输入验证码',{type: 'error'})
        }
      }
      this.$axios("POST",url,data,'登录中')
      .then(res => {
        if(res.code===1) {
          this.$message.success('登录成功')
          localStorage.setItem('hbzg',res.data.token)
          this.getUserInfo() //获取用户信息
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取用户信息
    getUserInfo() {
      this.$axios("POST","/user/getUserInfo",{})
      .then(res => {
        if(res.code===1) {
          localStorage.setItem('hbInfo',JSON.stringify(res.data))
          this.$store.commit('setUserInfo',res.data)
          this.$router.push('/')
        }else {
          localStorage.removeItem('hbzg')
          this.$alert('获取用户信息失败，请重试',{type: 'error'})
        }
      })
    },
  },
  created() {
    this.getCates() //获取行业分类
    setTimeout(()=>{
      this.current = this.$route.query.type
    },100)
  }
}
</script>

<style scoped>
  .homego {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 16px;
    color: #666666;
    cursor: pointer;
  }
  .login_left_bg{
    width: 65vw;
    height: 100vh;
    overflow: hidden;
  }
  .login_left_bg img{
    width:  100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .hover1:hover{
    text-decoration: none;
  }
  .login_tab{
    font-size: 1.5rem;
    color: #000000;
    transition: all 0.3s;
    cursor: pointer;
  }
  .login_tab.active{
    color: #1572F9;
    font-weight: bold;
    position: relative;
  }
  .login_tab.active::before{
    content: '';
    background-color: #1572F9;
    display: block;
    position: absolute;
    bottom: -10px;
    width: 50%;
    height: 3px;
    left: 50%;
    transform: translate(-50%);
  }
  .input_warp{
    width: 27.69rem;
    margin-top: 1.44rem;
  }
  .input_warp ::v-deep.el-input__inner{
    width: 100%;
    height: 3.46rem;
    background: #FFFFFF;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.13rem solid #DCDFE6;
    font-size: 18px;
  }
  .login_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    color: #ffffff;
    cursor: pointer;
  }
  .login_button.gray{
    background-color: #666666;
  }
  .getCode{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 25px;
    color: #1572F9;
    font-size: 18px;
    z-index: 22;
    cursor: pointer;
  }
  .checked{
    width: 20px;
    height: 19px;
    cursor: pointer;
  }
</style>