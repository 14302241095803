<template>
  <div>
    <!--上面登录 注册-->
    <!--中间logo和搜索-->
    <div class="nav_center difcac mrTop10 mrBtm10">
      <div style="width: 1149px;" class="dif alc">
        <!--logo-->
        <div class="logo">
          <img src="@/assets/logo.png" style="width: auto;height: 92px" alt="">
        </div>
        <!--搜索框-->
        <div>
          <div class="dif alc">
            <!--地址选择-->
            <div class="e_select posr mrLft30">
              <div class="posr" style="z-index: 333">
                <div class="dif alc pointer">
                  <div class="col333" @click="openAddress()">{{$store.state.address}}</div>
                  <i class="el-icon-arrow-down col333 fs14"></i>
                </div>
                <!--<div @click="openAddress()" style="position:absolute;top: 0;left: 0;width: 100%;height: 100%;z-index: 333">济南</div>-->
              </div>
              <el-cascader style="position:absolute;bottom: 0;left: 0;z-index: 22"
                           ref="address"
                           size="large"
                           @change="changeCitys"
                           :options="region"
                           v-model="address">
              </el-cascader>
            </div>
            <!--搜索-->
            <div style="margin-left: 36px;">
              <div class="difb alc">
                <div class="search_warp difb alc" >
                  <input type="text" placeholder="请输入内容" v-model="keyword" @keyup.enter="search()">
                  <div class="search_button difcac" @click="search()" >搜 索</div>
                </div>

              </div>
            </div>
          </div>
          <!--导航1-->
          <div style="margin-top: 10px;width: 585px;margin-left: 217px;" class="difb alc">
            <div class="pointer hover fs16 col000 center_item" :class="{'top_active': topCurrentPath===item.path}"
                 v-for="(item,index) in tabs" @click="goPath(item)"
                 :key="index">{{item.name}}</div>
          </div>
        </div>
        <!--发布 登录-->
        <div>
          <div class="login_button difcac" v-if="!userInfo.nickname" @click="goLogin(0)">登录/注册</div>
          <div class="dife alc" v-else>
            <el-popover
                placement="top"
                width="160"
                trigger="hover">
              <div class="difcac">
                  <el-button type="danger" size="mini" @click="logout()">退出登录</el-button>
              </div>
              <div class="login_text mrLft20 one_overflow tct hover " slot="reference" @click="goPath({id: 1,name: '个人中心',path: '/me/myInfo'})">
                {{userInfo.nickname}}
              </div>
            </el-popover>
            <!--<div class="fs16 col333 mrLft10 hover pointer" @click="logout()">退出登录</div>-->
          </div>
          <div class="login_button difcac mrTop10" @click="goPath({id: 6})">我要发布</div>
          <!--<div class="pointer hover fs16 col000 difcac center_item button_center" @click="goPath({id: 6})">我要发布</div>-->
        </div>
      </div>
    </div>
    <!--底部导航栏-->
    <div class="nav_bottom">
      <div class="difcac mr0At h100 nav_bottom_cont">
        <div class="nav_item difcac h100 " :class="{'active': currentPath==item.path}" @click="goPath(item)"
             v-for="(item,index) in navList" :key="index">{{item.name}}</div>
      </div>
    </div>

    <transition enter-active-class="animation-scale-up" leave-active-class="animation-scale-up reverse">
      <add-list v-if="showAddList" @hideWarp="hide" ></add-list>
    </transition>
  </div>
</template>

<script>
import AddList from "@/components/common/addList";
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'

import {
  mapState,
  mapMutations
}from 'vuex';
export default {
  name: "navbar",
  components: {AddList},
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  data() {
    return {
      region: provinceAndCityData,
      address: '获取中',
      code: '',//省市区code
      keyword: '',//搜索关键词
      tabs: [ //搜索下方的tab
        {id: 1,name: '个人中心',path: '/me/myInfo'},
        {id: 2,name: '我的订单',path: '/me/myOrder'},
        {id: 3,name: '我的收藏',path: '/myCollect'},
        {id: 4,name: '购物车',path: '/cart'},
        {id: 5,name: '积分兑换',path: '/integralMall'},
      ],
      navList: [ //导航栏
        {id: 7,name: '首页',path: '/'},
        {id: 8,name: '求购设备信息',path: '/wangtobuy'},
        {id: 9,name: '安装工程信息',path: '/insworks'},
        {id: 10,name: '物流货运信息',path: '/logistics'},
        {id: 11,name: '人才招聘',path: '/recruit'},
        {id: 12,name: '求职信息',path: '/jobwanted'},
        {id: 13,name: '商城',path: '/industry'},
        // {id: 14,name: '日用品商城',path: '/daily'},
        {id: 15,name: '二手设备',path: '/secondhand'},
      ],
      currentPath: '/',//当前选中的路由
      topCurrentPath: '/',
      showAddList: false,//是否显示发我要发布列表
    }
  },
  methods: {
    // 选择城市
    changeCitys(e) {
      if(CodeToText[e[1]]==='市辖区') {
        this.address = CodeToText[e[0]]
        console.log(this.address);
        this.$store.commit('setAddress',{code: e[1],address: CodeToText[e[0]]})
      }else {
        this.address = CodeToText[e[1]]
        this.$store.commit('setAddress',{code: e[1],address: CodeToText[e[1]]})
      }
      this.$router.go(0)
    },
    openAddress() {
      this.$refs.address.toggleDropDownVisible(true)
    },
    logout() { //退出登录
      this.$confirm('确认要退出该账户吗？',{type: 'info'})
      .then(con => {
        localStorage.removeItem('hbzg')
        localStorage.removeItem('hbInfo')
        this.$store.commit('logout')
        this.$router.push({
          path: '/login',
          query: {type: 0}
        })
      })
    },
    // 隐藏弹窗
    hide(e) {
      console.log(e);
      if(e.type===1) {
        this.showAddList = false
      }
    },
    // tab或nav点击
    goPath(item) {
      let token = localStorage.getItem('hbzg')
      if(item.id>=1 && item.id<=6 && !token) {
        return this.$alert('请登录后操作',{type: 'error'})
        .then(res=>{
          this.$router.push({
            path: '/login',query:{type: 0}
          })
        })
      }
      if(item.id===6) { //我要发布
        this.showAddList = true
      }
      if(item.id===4) { //跳转到购物车  再点击刷新
        console.log(this.$route.path);
        if(this.$route.path==='/cart') {
          this.$router.go(0)
        }else {
          this.$router.push({
            path: item.path
          })
        }
      }
      this.$router.push({
        path: item.path
      })
    },
    //跳转到登录页面
    goLogin(type) { //0 登录 1注册
      this.$router.push({
        path: '/login',
        query: {type: type}
      })
    },
    //显示发布
    showFabu(){
      this.showAddList = true
    },
    //搜索
    search() {
      if(this.keyword==='') {
        return this.$message.error('请输入搜索内容')
      }
      this.$router.push({
        path: '/search',
        query: {
          s: this.keyword,
          time: new Date().getTime()
        }
      })
    },
    //获取定位
    getLocation() {
      let address = localStorage.getItem('hb_address')
      let code = localStorage.getItem('hb_code')
      if(address && code) {
        this.$store.commit('setAddress',{
          address: address,
          code: code
        })
        return
      }
      var  geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 100000,
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        needAddress: true,
        extensions: 'all'
      });
      let that = this;
      geolocation.getCityInfo(function (status,res) {
        // 逆地理编码
        let geocoder = new AMap.Geocoder({
          extensions: 'all'
        })
        let lnglat = res.position
        geocoder.getAddress(lnglat,function (code,res1) {
          if (code === 'complete' && res1.info === 'OK') {
            let data = res1.regeocode.addressComponent
            that.code = data.adcode.substring(0, 4)+'00' //市级编码
            if(data.city) { //区名
              that.address = data.city
              that.$store.commit('setAddress',{address: data.city,code: that.code})
            }else if(data.province){ //直辖市
              that.address = data.province
              that.$store.commit('setAddress',{address: data.province,code: that.code})
            }
          }else {
            that.address = '济南市'
            that.code = '370100'
            that.$store.commit('setAddress',{address: that.address,code: that.code})
          }
        })
      });
    },
  },
  watch: {
    $route(newValue) {
      console.log(newValue);
      this.currentPath = newValue.matched[0].path || '/'
      this.topCurrentPath = newValue.path
    }
  },
  created() {
    this.getLocation();
    eventBus.$on('showFabu',this.showFabu)
    this.currentPath = this.$route.matched[0].path || '/'
    console.log(this.currentPath);
    this.topCurrentPath = this.$route.path
  }
}
</script>

<style scoped>
.e_select ::v-deep.el-input__suffix{
  display: none;
}

.e_select ::v-deep.el-input__inner{
  background: #FFFFFF;
  box-sizing: border-box;
  padding: 0 17px;
  font-size: 16px;
  opacity: 0;
}
 /* .top_active{
    color: #0B8EFE;
  }
  .nav_top{
    width: 100%;
    height: 34px;
    background: rgb(242,242,242);
    font-size: 14px;
    color: #414141;
  }
  .nav_center{
    width: 100%;
    height: 105px;
    color: #ffffff;
    background-color: #ffffff;
  }
  .nav_bottom{
    background-color: #1572F9;
    height: 50px;
    width: 100%;
  }
  .logo{
    color: #1572F9;
    font-size: 49px;
  }
  .search_warp{
    width: 583px;
    height: 46px;
    background: #1572F9;
    box-sizing: border-box;
    padding-left: 5px;
  }
  .search_warp input{
    width: 458px;
    height: 38px;
    background: #FFFFFF;
    font-size: 16px;
    box-sizing: border-box;
    padding: 0 8px;
  }
  .search_button{
    margin-right: 4px;
    box-sizing: border-box;
    height: 100%;
    padding: 0 35px;
    cursor: pointer;
  }
  .nav_item{
    width: 133px;
    font-size: 20px;
    color: #ffffff;
    transition: all 0.3s;
    cursor: pointer;
  }
  .nav_item:hover{
    font-size: 21px;
  }
  .nav_item.active{
    font-weight: bold;
    background-color: #0B8EFE;
  }*/
  .button_center{
    color: #ffffff;
    background-color: #1572F9;
    cursor: pointer;
    height:22px;
    border-radius: 15px;
    width: 80px
  }
 .top_active{
   color: #0B8EFE;
 }
 .nav_top{
   width: 100%;
   height: 34px;
   background: rgb(242,242,242);
   font-size: 14px;
   color: #414141;
 }
 .nav_center{
   padding: 5px 0;
   width: 100%;
   color: #ffffff;
   background-color: #ffffff;
 }
 .nav_bottom{
   /*background-color: #1572F9;*/
   height: 40px;
   width: 100%;
 }
 .nav_bottom_cont{
   background-color: #1572F9;
   width: 1200px;
 }
 .logo{
   color: #1572F9;
   font-size: 39px;
 }
 .search_warp{
   width: 752px;
   height: 40px;
   background: #1572F9;
   box-sizing: border-box;
   padding-left: 5px;
 }
 .search_warp input{
   width: 630px;
   height: 32px;
   background: #FFFFFF;
   font-size: 14px;
   box-sizing: border-box;
   padding: 0 8px;
 }
 .search_button{
   margin-right: 4px;
   box-sizing: border-box;
   height: 100%;
   padding: 0 35px;
   cursor: pointer;
 }
 .nav_item{
   /*width: 133px;*/
   width: 150px;
   font-size: 18px;
   color: #ffffff;
   transition: all 0.3s;
   cursor: pointer;
 }
 .nav_item:hover{
   font-size: 20px;
 }
 .nav_item.active{
   font-weight: bold;
   background-color: #0B8EFE;
 }
 .center_item{
   font-size: 14px;
 }
 .login_button{
   margin-left: 86px;
   font-size: 14px;
   color: #ffffff;
   background-color: #1572F9;
   cursor: pointer;
   height: 30px;
   border-radius: 15px;
   width: 90px
 }
 .login_text{
   font-size: 14px;
   color: #333333;
   cursor: pointer;
   width: 90px;
   word-break: break-all;
 }
</style>