<template>
  <div>
    <!--<navbar></navbar>-->
    <div class="pay_warp">
      <div class="pay_center">
        <div class="dif alc">
          <span class="fs32 col000">应付金额</span>
          <span style="font-size: 54px;color: #F75C5C">￥{{pay_amount}}</span>
          <span class="fs32 col000 mrLft10">元</span>
        </div>
        <!--支付-->
        <div class="difb alc" style="margin-top: 80px;width: 1100px;">
          <div class="dif difw">
            <div class="pay_item difcac mrRit20 mrBtm20" @click="payType=1" :class="{'active': payType===1}">
              <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">支付宝支付</span>
              <div class="check" v-if="payType===1"></div>
              <i class="el-icon-check check_icon" v-if="payType===1"></i>
            </div>
            <div class="pay_item difcac mrRit20 mrBtm20" @click="payType=2" :class="{'active': payType===2}">
              <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">微信支付</span>
              <div class="check" v-if="payType===2"></div>
              <i class="el-icon-check check_icon" v-if="payType===2"></i>
            </div>
            <div class="pay_item difcac mrRit20 pdLft10 pdRit10 mrBtm20" @click="payType=3" :class="{'active': payType===3}" v-if="type!=0">
              <img src="@/assets/images/yue.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">余额支付(可用余额￥{{userInfo.money}})</span>
              <div class="check" v-if="payType===3"></div>
              <i class="el-icon-check check_icon" v-if="payType===3"></i>
            </div>
            <div  class="pay_item difcac mrRit20  pdLft10 pdRit10 mrBtm20" @click="payType=4" :class="{'active': payType===4}" v-if="type!=0">
              <img src="@/assets/images/dikou.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">抵扣包(剩余次数{{userInfo.dk_number}})</span>
              <div class="check" v-if="payType===4"></div>
              <i class="el-icon-check check_icon" v-if="payType===4"></i>
            </div>
          </div>
          <div class="pay_button difcac" @click="nowPay()">立即支付</div>
        </div>
      </div>
    </div>

    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
  name: "submitPay",
  components: {Navbar},
  data() {
    return {
      type: '0', //1=工程设备求购,2=安装工程信息,3=物流货运信息,4=人才招聘,5=求职信息,6=二手设备
      payType: 1,//支付类型 1支付宝支付 2微信支付 3余额支付
      id: '',//支付id 或 查看详情的id
      pay_amount: '0.01',//支付价格

      page: 0,//上一页带过来的页数
      path: '',//上一页路径
      userInfo: {
        dk_number: 0,//抵扣包
        money: 0,//余额
      },
      showPay: false,
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
    }
  },
  methods: {
    // 进入标签页
    onshow(e) {
      if (e.target.visibilityState == "visible") {
        this.getUserInfo()
      } else {
        console.log('离开当前标签页');
      }
    },
    //查看支付
    showPayInfo() {
      this.$axios("POST","/common/showFee",{
        post_id: this.id,
        type: this.type,
        pay_type: this.payType
      },'加载中').then(res => {
        if(res.code===1) {
          if(this.payType===1) { //支付宝支付
            this.showAlipay = true
            // 打开支付宝form表单
            const div = document.createElement('div')
            div.innerHTML = res.data.pay_data
            document.body.appendChild(div)
            document.forms[0].acceptCharset = "UTF-8"
            document.forms[0].target = "_blank"
            document.forms[0].submit()
          }else if(this.payType===2){ //微信支付
            this.showWeChatCode = true
            this.imgUrl = res.data.pay_data
          }else { //余额支付
            this.$alert('支付成功',{type: 'success'})
            .then(e => {
              this.goDetail()
            }).catch(err=>{
              this.goDetail()
            })
          }
        }else {
          if(this.payType!=4) {
            this.$alert(res.msg,{type: 'error'})
          }else if(this.payType==4) {
            this.$confirm(res.msg,{
              type: 'error',
              confirmButtonText: '去购买'
            }).then(con => {
              let path = this.$router.resolve({
                path: '/me/deduction'
              })
              window.open(path.href)
            })
          }
        }
      })
    },
    //跳转到详情
    goDetail() {
      let url = ''
      if(this.type==1) url = '/wangtobuy/detail'//工程设备
      if(this.type==2) url = '/insworks/detail'//安装工程
      if(this.type==3) url = '/logistics/detail'//物流货运
      if(this.type==4) url = '/recruit/detail'//人才招聘
      if(this.type==5) url = '/jobwanted/detail'//求职信息
      if(this.type==6) url = '/secondhand/detail'//二手设备
      this.$router.replace({
        path: url,
        query: {id: this.id},
      })
    },
    //获取支付参数
    nowPay() {
      if(this.type!=0) { //查看支付
        this.showPayInfo()
        return
      }
      //发布支付
      this.$axios("POST","/common/getPostPayData",{
        id: this.id,
        pay_type: this.payType
      },'加载中').then(res => {
        if(res.code===1) {
          if(this.payType===1) { //支付宝支付
            this.showAlipay = true
            // 打开支付宝form表单
            const div = document.createElement('div')
            div.innerHTML = res.data
            document.body.appendChild(div)
            document.forms[0].acceptCharset = "UTF-8"
            document.forms[0].target = "_blank"
            document.forms[0].submit()
          }else { //微信支付
            this.showWeChatCode = true
            this.imgUrl = res.data
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.go(0)
    },
    paySuccess() { //点击支付成功
      let url = '/post/ask_buy/getInfo',path="/wangtobuy/detail" //工程设备
      if(this.type==2) { //安装工程信息
        url = "/post/information/getInfo"
        path = '/insworks/detail'
      }else if(this.type==3) { //物流信息
        url = "/post/post_logistics_freight/getInfo"
        path = '/logistics/detail'
      }else if(this.type==4) { //人才招聘
        url = "/post/job_fair/getInfo"
        path = '/recruit/detail'
      }else if(this.type==5) { //求职信息
        url = "/post/job_intention/getInfo"
        path = '/jobwanted/detail'
      }else if(this.type==6) { //二手设备
        url = "/post/second_hand/getInfo"
        path = '/secondhand/detail'
      }
      this.$axios("POST",url,{id: this.id},'加载中')
      .then(res => {
        if(res.code===1) {
          if(res.data.is_pay==1) {
            this.$alert('未支付',{type: 'error'})
          }else {
            this.$router.replace({
              path: path,
              query: {id: this.id},
            })
          }
        }else {
          if(this.type!=0) {
            this.$router.push({
              path: this.path,
              query: {page: this.page}
            })
          }else {
            this.$router.back()
          }
        }
      })
    },
    //获取用户信息
    getUserInfo() {
      try{
        this.userInfo = JSON.parse(localStorage.getItem('hbInfo'))
      }catch (e) {}
      this.$axios("POST","/user/getUserInfo",{})
          .then(res => {
            if(res.code===1) {
              localStorage.setItem('hbInfo',JSON.stringify(res.data))
              this.$store.commit('setUserInfo',res.data)
              this.userInfo = JSON.parse(JSON.stringify(res.data))
            }else {
              localStorage.removeItem('hbzg')
              this.$alert('获取用户信息失败，请刷新重试',{type: 'error'})
            }
          })
    },
  },
  created() {
    if(this.$route.query.issub) { //发布支付
      this.id = this.$route.query.id
      this.pay_amount = this.$route.query.pay_amount
    }else { //查看支付
      this.type = this.$route.query.type
      this.id = this.$route.query.id
      this.page = this.$route.query.page
      this.path = this.$route.query.path
      this.pay_amount = this.$route.query.pay_amount
      console.log(this.page);
      console.log(this.path);
    }
    this.getUserInfo()
    document.addEventListener("visibilitychange", this.onshow);
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onshow);
  },
}
</script>

<style scoped>
  .pay_warp{
    background-color: #f1f1f1;
    height: 90vh;
  }
  .pay_center{
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding-left: 50px;
    padding-top: 67px;
  }
  .pay_item{
    min-width: 236px;
    height: 69px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 2px solid #868686;
    cursor: pointer;
    font-size: 28px;
    color: #383D42;
    overflow: hidden;
    position: relative;
  }
  .pay_item.active{
    border: 2px solid #337DF7;
    color: #337DF7;
  }
  .check{
    width: 41px;
    height: 41px;
    background-color: #3765FF;
    position: absolute;
    right: -20px;
    bottom: -20px;
    transform: rotate(45deg);
  }
  .check_icon{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 18px;
    color: #ffffff;
    z-index: 22;
  }
  .pay_button{
    width: 236px;
    height: 69px;
    background: #FB6124;
    border-radius: 6px 6px 6px 6px;
    color: #ffffff;
    font-size: 28px;
    cursor: pointer;
    transition: all 0.1s;
  }
  .pay_button:hover{
    background: #ff4d0b;
  }
</style>