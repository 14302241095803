<template>
  <div style="width: 1114px;min-height: 90vh" class="bgff" v-loading="showLoading">
    <!--分类-->
    <div class="tabs dif alc">
      <div class="tab_item difcac hover" @click="changeTabs(index,item)"
           v-for="(item,index) in tabs" :key="index" :class="{'active': current===index}">{{item.name}}</div>
    </div>
    <!--表头-->
    <div class="dif alc box fs12" style="margin-top: 13px;color: #868686;padding-bottom: 5px;" v-if="current!=6">
      <div style="margin-left: 28px;margin-right: 558px; ">订单信息</div>
      <div style="margin-right: 87px;">收货人</div>
      <div style="margin-right: 87px;">金额</div>
      <div style="margin-right: 87px;">状态</div>
      <div>操作</div>
    </div>
    <!--商城订单-->
    <div class="order_list" v-if="current!=6">
      <div style="padding-top: 100px;" v-if="list.length<=0">
        <el-result icon="info" title="暂无订单"></el-result>
      </div>
      <div class="order_item dif alc" v-for="(item,index) in list" :key="index">
        <!--订单信息-->
        <div class="fs14" style="color: #A0A0A0;width: 194px;margin-right: 10px;">
          <p class="two_overflow mrBtm10">订单号：{{item.order_no}}</p>
          <!--<p class="one_overflow mrBtm10">交易时间：1234</p>-->
          <p class="one_overflow mrBtm10">商品金额：{{item.pay_price}}</p>
          <p class="one_overflow mrBtm10">运费：{{item.express_price}}</p>
          <!--<p class="one_overflow ">有 发货 发货</p>-->
        </div>
        <!--图片-->
        <img :src="item.goods_arr[0].image" alt="" class="order_img">
        <!--数量-->
        <p class="fs14" style="color: #A0A0A0;width: 60px;margin-right: 180px;">x{{item.goods_arr.length}}</p>
        <!--收货人-->
        <p class="fs14 tct" style="color: #414141;word-break: break-all;width: 100px;">
          <span style="display: block" class="one_overflow">{{item.name}}</span>
          <span style="display: block" class="one_overflow">{{item.mobile}}</span>
        </p>
        <!--金额-->
        <p class="fs14 tct one_overflow" style="color: #414141;word-break: break-all;width: 100px;margin-left: 13px;">￥{{item.pay_price}}</p>
        <!--状态-->
        <div class="fs14 tct" style="color: #A0A0A0;width: 120px;">{{item.status_text}}</div>
        <!--操作-->
        <div class="difcc fs14 mrLft30" style="width: 120px;">
          <p class="pointer hover w100" style="color: #414141;" @click="goDetail(item)">订单详情</p>
          <!--<p class="pointer hover w100" style="color: #FB6124;margin-bottom: 24px;" @click="goAfter()">申请售后</p>-->
          <p class="pointer hover w100" style="color: #FB6124;margin-top: 24px;" @click="showPay=true;setId=item.id" v-if="item.status==1">立即支付</p>
          <p class="pointer hover w100" style="color: #FB6124;margin-top: 24px;" @click="confirmOrder(item)" v-if="item.status==3">确认收货</p>
          <p class="pointer hover w100" style="color: #FB6124;margin-top: 24px;" @click="goAfter(item)" v-if="item.status==4">申请售后</p>
          <p class="pointer hover w100" style="color: #A0A0A0;margin-top: 24px;" v-if="item.status==1" @click="cancelOrder(item)">取消订单</p>
        </div>
      </div>
    </div>

    <div class="dif alc box fs12" style="margin-top: 13px;color: #868686;padding-bottom: 5px;" v-if="current==6">
      <div style="margin-left: 28px;margin-right: 558px; ">订单信息</div>
      <div style="margin-right: 87px;opacity: 0;">原因</div>
      <div style="margin-right: 87px;">金额</div>
      <div style="margin-right: 87px;">状态</div>
      <div>操作</div>
    </div>
    <!--售后订单-->
    <div class="order_list" v-if="current==6">
      <div style="padding-top: 100px;" v-if="afterList.length<=0">
        <el-result icon="info" title="暂无订单"></el-result>
      </div>
      <div class="order_item dif alc" v-for="(item,index) in afterList" :key="index">
        <!--订单信息-->
        <div class="fs14" style="color: #A0A0A0;width: 194px;margin-right: 10px;">
          <p class="two_overflow mrBtm10">售后订单号：{{item.order_refund_no}}</p>
          <!--<p class="one_overflow mrBtm10">交易时间：1234</p>-->
          <p class="one_overflow mrBtm10">售后金额：{{item.refund_price}}</p>
          <!--<p class="one_overflow mrBtm10">运费：{{item.express_price}}</p>-->
          <!--<p class="one_overflow ">有 发货 发货</p>-->
        </div>
        <!--图片-->
        <img :src="item.goods_arr[0].image" alt="" class="order_img">
        <!--数量-->
        <p class="fs14" style="color: #A0A0A0;width: 60px;margin-right: 180px;">x{{item.goods_arr.length}}</p>
        <!--退货原因-->
        <p class="fs14 tct two_overflow" style="color: #414141;word-break: break-all;width: 90px;">
          <!--{{item.desc}}-->
        </p>
        <!--金额-->
        <p class="fs14 tct one_overflow" style="color: #414141;word-break: break-all;width: 100px;margin-left: 13px;">￥{{item.refund_price}}</p>
        <!--状态-->
        <div class="fs14 tct" style="color: #A0A0A0;width: 120px;">{{item.status_text}}</div>
        <!--操作-->
        <div class="difcc fs14 mrLft30" style="width: 120px;">
          <p class="pointer hover w100" style="color: #414141;" @click="goAfterDetail(item)">售后详情</p>
          <p class="pointer hover w100" style="color: #FB6124;margin-top: 24px;" v-if="item.status==2" @click="goReturn(item)">填写退货单</p>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="difcac home_page" style="margin: 38px 0 50px 0;">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page="page"
          @current-change="changeSize">
      </el-pagination>
    </div>

    <!--支付方式选择-->
    <el-dialog
        title="选择支付方式"
        :visible.sync="showPay"
        width="40%"
        top="25vh">
      <div>
        <div class="difcac">
          <div class="pay_item difcac" @click="payType=1" :class="{'active': payType===1}">
            <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">支付宝支付</span>
            <div class="check" v-if="payType===1"></div>
            <i class="el-icon-check check_icon" v-if="payType===1"></i>
          </div>
          <div class="pay_item difcac mrLft20" @click="payType=2" :class="{'active': payType===2}">
            <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">微信支付</span>
            <div class="check" v-if="payType===2"></div>
            <i class="el-icon-check check_icon" v-if="payType===2"></i>
          </div>
        </div>
        <div class="dife alc mrTop30">
          <el-button @click="showPay=false">取 消</el-button>
          <el-button type="primary" @click="pay()">支 付</el-button>
        </div>
      </div>
    </el-dialog>
    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "orderList",
  data() {
    return {
      tabs: [
        {id: 99,name: '全部订单'},
        {id: 1,name: '待付款'},
        {id: 2,name: '待发货'},
        {id: 3,name: '待收货'},
        {id: 4,name: '已完成'},
        {id: 5,name: '已取消'},
        {id: 6,name: '售后/退货'},
      ],
      current: 0,
      showLoading: false,
      page: 1,
      limit: 5,
      allPage: 1,//总页数
      list: [],//订单列表
      afterList: [],//售后订单列表

      setId: '',//当前选中的id

      showPay: false,
      payType: 1,//1支付宝支付 2微信支付
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
    }
  },
  methods: {
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.go(0)
    },
    paySuccess() { //点击支付成功
      this.$router.go(0)
    },
    //确认收货
    confirmOrder(item) {
      this.$confirm('请确认无误后收货',{type: 'warning'})
          .then(con => {
            this.showLoading = true
            this.$axios("POST","/Order/affirm",{id: item.id})
                .then(res => {
                  this.showLoading = false
                  if(res.code===1) {
                    this.$message.success('确认收货成功')
                    this.page = 1
                    this.getOrderList() //获取订单列表
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },
    //取消订单
    cancelOrder(item) {
      this.$confirm('确认要取消该订单吗？',{type: 'warning'})
      .then(con => {
        this.showLoading = true
        this.$axios("POST","/Order/cance",{id: item.id})
        .then(res => {
          this.showLoading = false
          if(res.code===1) {
            this.$message.success('删除成功')
            this.page = 1
            this.getOrderList() //获取订单列表
          }else {
            this.$alert(res.msg,{type: 'error'})
          }
        })
      })
    },
    //支付
    pay() {
      let data = {
        id: this.setId,
        pay_type: this.payType
      }
      this.$axios("POST","/Order/orderListPay",data)
          .then(res => {
            this.showPay = false
            if (res.code===1) {
              if(data.pay_type===1) {
                this.showAlipay = true
                // 打开支付宝form表单
                const div = document.createElement('div')
                div.innerHTML = res.data
                document.body.appendChild(div)
                document.forms[0].acceptCharset = "UTF-8"
                document.forms[0].target = "_blank"
                document.forms[0].submit()
              }else {
                this.showWeChatCode = true
                this.imgUrl = res.data
              }
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    // 申请售后
    goAfter(item) {
      localStorage.setItem('hbAfter',JSON.stringify(item))
      this.$router.push({
        path: '/me/myOrder/after',
        query: {
          id: item.id
        }
      })
    },
    //订单详情
    goDetail(item) {
      let path = this.$router.resolve({
        path: '/orderDetail',
        query: {
          id: item.id
        }
      })
      window.open(path.href)
    },
    //售后订单详情
    goAfterDetail(item) {
      let path = this.$router.resolve({
        path: '/afterDetail',
        query: {
          id: item.id
        }
      })
      window.open(path.href)
    },
    //填写退货单
    goReturn(item) {
      localStorage.setItem('hbAfter',JSON.stringify(item))
      this.$router.push({
        path: '/me/myOrder/returnSubmit',
        query: {
          id: item.id
        }
      })
    },
    //选择状态
    changeTabs(index,item) {
      this.current = index
      this.page = 1
      if(this.current===6) {
        this.getAfterList()
      }else {
        this.getOrderList()
      }
    },
    //切换页数
    changeSize(e) {
      this.page = e
      if(this.current===6) {
        this.getAfterList()
      }else {
        this.getOrderList()
      }
    },
    //获取订单列表
    getOrderList() {
      let data = {
        page: this.page,
        limit: this.limit,
        status: this.tabs[this.current].id
      }
      this.showLoading = true
      this.$axios("POST","/Order/getList",data)
      .then(res => {
        this.showLoading = false
        if (res.code===1) {
          this.list = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
          console.log(Math.ceil(res.data.total/this.limit));
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取售后订单列表
    getAfterList() {
      let data = {
        page: this.page,
        limit: this.limit
      }
      this.showLoading = true
      this.$axios("POST","/order_refund/getlist",data)
      .then(res => {
        this.showLoading = false
        if(res.code===1) {
          this.afterList = res.data
        }else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  created() {
    this.getOrderList() //获取订单列表
  }
}
</script>

<style scoped>
.order_list{
  border-top: 1px solid #1572F9;
}
.order_item{
  padding: 16px 14px 19px 21px;
  border-bottom: 1px solid #1572F9;
}
.order_img{
  width: 106px;
  height: 106px;
  background-color: #EEEEEE;
  margin-right: 34px;
}
.tabs{
  height: 29px;
  background: #1572F9;
}
.tab_item{
  font-size: 14px;
  color: #ffffff;
  box-sizing: border-box;
  padding: 0 21px;
  cursor: pointer;
  height: 29px;
}
.tab_item.active{
  background-color: #3EA6FF;
}


.pay_item{
  width: 236px;
  height: 69px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #868686;
  cursor: pointer;
  font-size: 28px;
  color: #383D42;
  overflow: hidden;
  position: relative;
}
.pay_item.active{
  border: 2px solid #337DF7;
  color: #337DF7;
}
.check{
  width: 41px;
  height: 41px;
  background-color: #3765FF;
  position: absolute;
  right: -20px;
  bottom: -20px;
  transform: rotate(45deg);
}
.check_icon{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  color: #ffffff;
  z-index: 22;
}
</style>