<template>
  <div style="background-color: #FAFAFA;padding-bottom: 28px;">
    <div style="width: 1200px;" class="mr0At pdTop10">
      <!--状态-->
      <div class="status_warp difb alc">
        <p class="fs28" style="color: #414141">{{info.status_text}}</p>
        <div class="order_button difcac hover pointer colfff" v-if="info.status==2" @click="confirmOrder()">确认收货</div>
      </div>
      <!--物流信息-->
      <div class="info_warp" v-if="info.express">
        <p class="fs32" style="color: #414141">物流信息</p>
        <p class="fs28 mrTop30" style="color: #868686;margin-bottom: 24px;">物流公司：{{info.express}}</p>
        <p class="fs28" style="color: #868686">物流信息：{{info.express_no}}</p>
      </div>
      <!--订单信息-->
      <div class="detail_warp">
        <p class="tct fs32 col000" style="margin-bottom: 58px;">订单信息</p>
        <!--收货地址-->
        <p class="fs24 col000 tct" style="margin-bottom: 60px;">
          收货地址：{{info.name}}，{{info.mobile}}，{{info.address}}
        </p>
        <!--订单信息-->
        <p class="fs24 col000 tct" style="margin-bottom: 64px;">
          订单信息：下单时间:{{info.createtime_str}}   订单号:{{info.order_no}}
        </p>
        <!--商品信息-->
        <div class="dif alc fs14 col000">
          <div style="margin-left: 141px;margin-right: 179px;">商品信息</div>
          <div style="margin-right: 196px;opacity: 0;">状态</div>
          <div style="margin-right: 171px;">单价（积分）</div>
          <div style="margin-right: 201px;">数量</div>
          <div>总价</div>
        </div>
        <div class="goods_warp_order">
          <div class="goods_item_order dif alc" >
            <!--图片 名称 规格-->
            <div class="dif alc">
              <div class="order_img">
                <img :src="$store.state.imgUrl + info.good_image" class="w100 h100" alt="">
              </div>
              <div style="width: 160px;">
                <p class="fs18 col000 one_overflow">{{info.name}}</p>
                <p class="two_overflow fs14" style="margin-top: 8px;color: #A0A0A0">{{info.attr_txt}}</p>
              </div>
            </div>
            <!--状态-->
            <div class="one_overflow tct fs14 col000" style="width: 120px;margin-left: 20px;"></div>
            <!--单价-->
            <div style="color: #414141;width: 150px;margin-left: 105px;" class="fs20 tct">￥{{ (info.amount/info.num).toFixed(2)}}</div>
            <!--数量-->
            <div style="margin-left: 110px;">
              <el-input-number size="mini" :min="1" v-model="info.num" disabled :controls="false"></el-input-number>
            </div>
            <!--总价-->
            <div class="tct fs24" style="color: #D64040;width: 150px;margin-left: 80px">{{info.amount}}</div>
          </div>
        </div>
        <!--应付金额-->
        <div class="dife alc" style="padding-right: 53px;padding-top: 18px;padding-bottom: 46px;">
          <div class="dif alc">
            <!--<span class="fs32 col000">应付金额</span>-->
            <span style="font-size: 54px;color: #F75C5C">{{info.amount}}</span>
            <span class="fs32 col000">积分</span>
          </div>
        </div>
      </div>
    </div>


    <!--支付方式选择-->
    <el-dialog
        title="选择支付方式"
        :visible.sync="showPay"
        width="40%"
        top="25vh">
      <div>
        <div class="difcac">
          <div class="pay_item difcac" @click="payType=1" :class="{'active': payType===1}">
            <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">支付宝支付</span>
            <div class="check" v-if="payType===1"></div>
            <i class="el-icon-check check_icon" v-if="payType===1"></i>
          </div>
          <div class="pay_item difcac mrLft20" @click="payType=2" :class="{'active': payType===2}">
            <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
            <span class="mrLft10">微信支付</span>
            <div class="check" v-if="payType===2"></div>
            <i class="el-icon-check check_icon" v-if="payType===2"></i>
          </div>
        </div>
        <div class="dife alc mrTop30">
          <el-button @click="showPay=false">取 消</el-button>
          <el-button type="primary" @click="pay()">支 付</el-button>
        </div>
      </div>
    </el-dialog>
    <!--支付宝支付-->
    <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
      <div class="difcc">
        <h2 class="tct mrBtm20">支付宝支付</h2>
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close2()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!--微信-->
    <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
      <div v-if="imgUrl" class="difcc">
        <h2 class="tct mrBtm20">微信支付</h2>
        <img :src="imgUrl" style="width: 200px;height: auto">
        <div class="mrTop20 difcac">
          <div>
            <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
          </div>
          <div class="mrLft30">
            <el-button type="info" plain round @click="close()">重新支付</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "iorderDetail",
  data() {
    return {
      num: 1,
      id: '',//订单id
      info: {
        "id": 5,
        "order_no": "",
        "num": 1,
        "good_image": "",
        "attr_txt": "",
        "name": "",
        "amount": 0,
        "status": "",
        "username": "",
        "mobile": "",
        "address": "",
        "remark": "",
        "send_time": 0,
        "express": "",
        "express_no": "",
        "confirm_time": 0,
        "createtime": 1651803550,
        "status_text": "",
        "createtime_str": "",
        "send_time_text": ""
      },
      showPay: false,
      payType: 1,//1支付宝支付 2微信支付
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
    }
  },
  methods: {
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.go(0)
    },
    paySuccess() { //点击支付成功
      this.$router.go(0)
    },
    //确认收货
    confirmOrder() {
      this.$confirm('请确认无误后收货',{type: 'warning'})
          .then(con => {
            this.showLoading = true
            this.$axios("POST","/integral/affirm",{id: this.id})
                .then(res => {
                  this.showLoading = false
                  if(res.code===1) {
                    this.$message.success('确认收货成功')
                    setTimeout(()=>{
                      this.getDetail()
                    },1000)
                  }else {
                    this.$alert(res.msg,{type: 'error'})
                  }
                })
          })
    },
    //支付
    pay() {
      let data = {
        id: this.id,
        pay_type: this.payType
      }
      this.$axios("POST","/Order/orderListPay",data)
          .then(res => {
            this.showPay = false
            if (res.code===1) {
              if(data.pay_type===1) {
                this.showAlipay = true
                // 打开支付宝form表单
                const div = document.createElement('div')
                div.innerHTML = res.data
                document.body.appendChild(div)
                document.forms[0].acceptCharset = "UTF-8"
                document.forms[0].target = "_blank"
                document.forms[0].submit()
              }else {
                this.showWeChatCode = true
                this.imgUrl = res.data
              }
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    //获取订单详情
    getDetail() {
      this.$axios("POST","/integral/getOrderInfo",{id: this.id},'加载中')
          .then(res => {
            if(res.code==1) {
              this.info = res.data
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail() //获取订单详情
  }
}
</script>

<style scoped>
.detail_warp{
  width: 1200px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 49px;
}
.info_warp{
  width: 100%;
  height: 238px;
  background: #FFFFFF;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding-left: 33px;
  padding-top: 33px;
}
.status_warp{
  height: 132px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  margin-bottom: 12px;
  box-sizing: border-box;
  padding-left: 34px;
  padding-right: 75px;
}
.order_button{
  width: 180px;
  height: 40px;
  background: #1572F9;
  border: 1px solid #1572F9;
  font-size: 16px;
}
.goods_warp_order{
  background: #FFFFFF;
  box-sizing: border-box;
  border: 1px solid #E8E8E8;
  margin-top: 12px;
}
.goods_item_order{
  box-sizing: border-box;
  padding: 21px 37px ;
  padding-right: 0;
}
.order_img{
  width: 90px;
  height: 90px;
  margin-right: 21px;
  background-color: #EEEEEE;
}


.pay_item{
  width: 236px;
  height: 69px;
  background: #FFFFFF;
  border-radius: 6px 6px 6px 6px;
  border: 2px solid #868686;
  cursor: pointer;
  font-size: 28px;
  color: #383D42;
  overflow: hidden;
  position: relative;
}
.pay_item.active{
  border: 2px solid #337DF7;
  color: #337DF7;
}
.check{
  width: 41px;
  height: 41px;
  background-color: #3765FF;
  position: absolute;
  right: -20px;
  bottom: -20px;
  transform: rotate(45deg);
}
.check_icon{
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  color: #ffffff;
  z-index: 22;
}
</style>