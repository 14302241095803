<template>
  <div>
    <!--<navbar></navbar>-->
    <!--轮播图-->
    <div class="swiper_warp difb alc">
      <div class="swiper">
        <!--<el-carousel height="31vw">-->
        <el-carousel height="237px">
          <el-carousel-item v-for="item in swiper">
            <img :src="item.slideimage" class="w100 h100 bgcb pointer" @click="goSwiper(item)">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--新  公告-->
      <div class="notice">
        <!--公告-->
        <div class="difb alc" style="padding-left: 10px;padding-right: 32px;">
          <div class="dif alc">
            <img src="@/assets/images/icon_gonggao.png" style="width: 28px;height: 28px;margin-right: 5px;">
            <div class="fs20" style="color: #1572F9">最新成交</div>
          </div>
          <!--<img src="@/assets/images/icon_gengduo.png" style="width: 12px;height: 12px;">-->
        </div>
        <div class="notice_border"></div>
        <div class="newsList" @mouseover="mouseOver" @mouseout="mouseOut">
          <ul id="con1" ref="con1" :class="{ anim: animate }">
            <li v-for="(item, index) in items" class="news_item one_overflow">
              {{item}}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--内容-->
    <div style="width: 1200px;margin: 30px auto;">
      <!--分类-->
      <div>
        <el-tabs v-model="activeName" @tab-click="changeTabs">
          <el-tab-pane v-for="(item,index) in cates" :key="index"
                       :label="item.name" :name="item.id"></el-tab-pane>
        </el-tabs>
      </div>
      <!--商户列表-->
      <div class="shop_warp dif difw" v-if="shopList.length>0">
        <div class="shop_item" v-for="(item,index) in shopList" :key="index" @click="goShopDetail(item)">
          <img :src="item.cover_image" class="w100" style="display: block;height: 215px;" :title="item.name" :alt="item.name">
          <div class="shop_cont posr">
            <div class="shop_vip difcac" v-if="item.is_top==1">VIP商户</div>
            <p class="one_overflow col333 bold fs14">{{item.name}}</p>
            <p class="one_overflow col333" style="font-size: 12px;margin-top: 4px;">{{item.info_address}}</p>
          </div>
        </div>
      </div>
      <div v-if="shopList.length<=0" class="box w100" style="padding: 60px 0;">
        <el-result icon="info" title="提示" subTitle="暂无内容"></el-result>
      </div>
      <!--分页-->
      <div class="difcac home_page" style="margin: 38px 0 50px 0;">
        <el-pagination
            background
            layout="prev, pager, next"
            :page-count="allPage"
            :current-page="page"
            @current-change="changeSize">
        </el-pagination>
      </div>
    </div>

    <!--系统公告-->
    <el-dialog
        title="系统公告"
        :visible.sync="showAffiche"
        width="30%"
        center>
      <div v-html="affiche" style="overflow-y: auto;max-height: 60vh"></div>
    </el-dialog>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
  name: 'Home',
  components: {
    Navbar
  },
  data() {
    return {
      showPopover: true,
      leftCurrent: 0,//当前选中的左侧分类
      leftCate: [
        {name: '行业分类'},{name: '建筑'},
      ],//左侧分类
      rightCurrent: 0,//当前选中的右侧分类
      rightGoods: [],//右侧商品
      showLoding: false,//显示加载
      swiper: [],//轮播图
      limit: 30,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数

      timer: null,
      items: [],
      animate: false,

      shopList: [],//商家列表
      activeName: '',
      cates: [],//分类
      affiche: '',//系统公告
      showAffiche: false,//显示系统公告
    }
  },
  methods: {
    //获取公告
    getAffiche() {
      this.$axios("POST","/index/getConfig",{name: 'affiche'})
      .then(res => {
        if(res.code==1) {
          this.affiche = res.data
          let isShowAffiche = sessionStorage.getItem('isShowAffiche')
          if(!isShowAffiche) {
            // this.showAffiche = true
            this.$notify({
              title: '系统公告',
              dangerouslyUseHTMLString: true,
              message: res.data,
              duration: 0
            });
            sessionStorage.setItem('isShowAffiche',1)
          }
        }
      })
    },
    //点击轮播图跳转
    goSwiper(item) {
      if(item.is_jump==2) {
        this.$router.push({
          path: '/shopDetail',
          query: {id: item.mch_id}
        })
      }
    },
    //获取轮播图
    getSwiper() {
      this.$axios("POST","/index/getRotationMap",{module: 2})
      .then(res => {
        if(res.code===1) this.swiper = res.data
      })
    },
    //选择左侧分类
    selectLeftCate(item,index) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = index
      item.check = true
      if(index===0) {
        this.page = 1
        this.getGoodsList()
      }
      return
      this.rightCurrent = 0
      this.page = 1
      this.getGoodsList()
    },
    //选择右侧分类
    selectRight(index,pindex) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = pindex
      this.rightCurrent = index
      this.rightGoods = 0
      this.page = 1
      this.getGoodsList()
    },
    //获取商城分类
    getGoodCate() {
      this.$axios("POST","/Goods/getGoodCate",{})
      .then(res => {
        if(res.code===1) {
          this.leftCate = res.data
          this.leftCate.unshift({id: '',name: '全部',san_arr: null})
          this.getGoodsList();
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取商品列表
    getGoodsList() {
      this.showLoding = true
      let data = {
        is_index: 1,
        page: this.page,
        limit: this.limit
      }
      if(this.leftCate[this.leftCurrent].id=='') {

      }else {
        data.cate_id = this.leftCate[this.leftCurrent].san_arr[this.rightCurrent].id
      }
      this.$axios("POST","/Goods/getGoodList",data)
      .then(res=>{
        this.showLoding = false
        if(res.code===1) {
          this.rightGoods = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      window.scrollTo({
        top: 0,//推荐使用，getBoundingClientRect 相对于当前视口的位置
        behavior: "smooth" // 平滑滚动
      });
      this.getList()
    },
    //商品详情
    goDetail(item) {
      let url = this.$router.resolve({
        path: '/goods',
        query: {
          id: item.id
        }
      })
      window.open(url.href)
    },
    //获取行业分类
    getIndustry() {
      this.$axios("POST","/index/getIndustry",{})
          .then(res => {
            if(res.code===1) {
              for(let item of res.data) {
                item.id = item.id+''
              }
              this.cates = res.data
              this.cates.unshift({id: '123456',name: '全部'})
              if(this.cates.length>0) {
                this.activeName = res.data[0].id+''
              }
              this.getList()
            }else {
              this.$alert(res.msg,{type: 'error'})
            }
          })
    },
    changeTabs(tab,event) {
      this.activeName = tab.name
      this.page  =1
      this.getList()
    },
    getList() { //获取商家列表
      let industry_id = this.activeName
      if(this.activeName=='123456') industry_id = ''
      this.$axios("POST","/mchs/getList",{
        page: this.page,
        limit: this.limit,
        industry_id: industry_id,
      }).then(res => {
        if(res.code===1) {
          this.shopList = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //商家详情
    goShopDetail(item) {

      this.$router.push({
        path: '/shopDetail',
        query: {
          id: item.id
        }
      })
    },

    //初始化动画
    initAnime() {
      this.$axios("POST","/common/getPostNewNotice",{})
      .then(res => {
        if(res.code==1) {
          for(let item of res.data) {
            this.items.push(item.text)
          }
          if(res.data.length>4) {
            this.timer=setInterval(this.scroll, 2000);
          }
        }
      })
    },
    // 开始滚动
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        this.items.push(this.items[0]);
        this.items.shift();
        this.animate = false; // margin-top 为0 的时候取消过渡动画
      }, 1500);
    },
    // 鼠标移入 暂停动画
    mouseOver(){
      clearInterval(this.timer)
    },
    // 鼠标移出 重新开始动画
    mouseOut(){
      this.timer=setInterval(this.scroll, 2000);
    },
  },
  mounted() {
    this.initAnime()
    this.getAffiche()
  },
  created() {
    this.getSwiper() //获取轮播图
    this.getIndustry() //获取分类
  },
}
</script>

<style scoped>
/*分类*/
/*分类*/
::v-deep .el-tabs__nav-wrap.is-scrollable{
  padding: 0 33px;
}
::v-deep .el-tabs__item{
  font-size: 18px !important;
  width: 140px !important;
  padding: 0 !important;
  text-align: center;
}
::v-deep .el-tabs__nav-scroll{
  padding-bottom: 20px !important;
}
::v-deep .el-tabs__nav-next .el-icon-arrow-right{
  font-size: 18px;
  padding-top: 5px;
  font-weight: bold;
}
::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
  font-size: 18px;
  padding-top: 5px;
  font-weight: bold;
}
::v-deep .el-tabs__item.is-active{
  color: #1572F9;
}
::v-deep .el-tabs__active-bar{
  bottom: -20px !important;
  height: 6px;
  background-color: #1572F9;
  width: 140px !important;
}
::v-deep .el-tabs__active-bar:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 20px solid #1572F9;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-100%);
}
::v-deep .el-tabs__nav-wrap::after{
  height: 6px;
}
.wtb_warp{
  box-sizing: border-box;
  padding: 0 23px;
}
.wtb_item{
  border-bottom: 1px solid #F2F2F2;
  padding-top: 10px;
  padding-bottom: 10px;
}

::v-deep .popper__arrow{
  border: 1px solid #1572F9 !important;
}
 /*.swiper{*/
 /*  !*width: 100%;*!*/
 /*  width: 1200px;*/
 /*  margin: 0 auto;*/
 /*}*/
 .left_cate{
   box-sizing: border-box;
   padding: 18px 0;
   width: 150px;
   /*height: 888px;*/
   background: #FFFFFF;
   overflow-y: auto;
   box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
   scrollbar-width: none; /* Firefox */
   -ms-overflow-style: none; /* IE 10+ */
 }
 .left_cate::-webkit-scrollbar {
   display: none; /* Chrome Safari */
 }
 .left_item{
   width: 100%;
   min-height: 34px;
   box-sizing: border-box;
   padding: 8px 4px;
   font-size: 14px;
   color: #000000;
   transition: all 0.3s;
   cursor: pointer;
 }
 .left_item:hover{
   font-weight: bold;
 }
 .left_item.active{
   color: #ffffff;
   background-color: #0B8EFE;
 }
 /*.right_goods{
   margin-left: 18px;
   width: 1200px;
   height: 888px;
   background: #FFFFFF;
   box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   scrollbar-width: none; !* Firefox *!
   -ms-overflow-style: none; !* IE 10+ *!
   box-sizing: border-box;
   padding: 0 20px;
 }*/
 .right_goods::-webkit-scrollbar {
   display: none; /* Chrome Safari */
 }
 /*.right_cate{
   scrollbar-width: none; !* Firefox *!
   -ms-overflow-style: none; !* IE 10+ *!
   box-sizing: border-box;
   overflow-x: auto;
   padding: 28px 26px;
   white-space: nowrap;
 }*/
 .right_cate::-webkit-scrollbar {
   display: none; /* Chrome Safari */
 }
 .right_item:nth-last-child(1) {
   margin-right: 0;
 }
 .right_item:hover{
   color: #1572F9;
 }

 /*.right_item{
   display: inline-block;
   font-size: 28px;
   color: #000000;
   transition: all 0.3s;
   margin-right: 90px;
 }*/
 .right_item.active{
   color: #1572F9;
 }
  /* .goods_item{
     width: 275px;
     height: 376px;
     background: #FFFFFF;
     box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
     border-radius: 4px 4px 4px 4px;
     margin-bottom: 25px;
     margin-right: 20px;
   }
   .goods_item:nth-child(4n) {
     margin-right: 0;
   }
   .goods_item .img{
     width: 275px;
     height: 241px;
     background: #f1f1f1;
     box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
     border-radius: 4px 4px 4px 4px;
     overflow: hidden;
   }
   .goods_item .img img{
     transition: all 0.3s;
     width: 100%;
     height: 100%;
   }
   .goods_item:hover .img img{
     transform: scale(1.1);
   }*/
 .home_page ::v-deep .number{
   width: 40px;
   height: 40px;
   padding: 6px 5px;
 }
 .home_page ::v-deep .more{
   width: 40px;
   height: 40px;
   padding: 6px 5px;
 }
 .home_page ::v-deep .btn-next{
   width: 40px;
   height: 40px;
   padding: 6px 5px;
 }
 .home_page ::v-deep .btn-prev{
   width: 40px;
   height: 40px;
   padding: 6px 5px;
 }

 /*商家*/
 .shop_warp{
   width: 1200px;
   background: #FFFFFF;
   box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.3);
   padding: 20px;
   padding-bottom: 15px;
 }
 .shop_item{
   width: 215px;
   overflow: hidden;
   /*height: 215px;*/
   margin-right: 21px;
   margin-bottom: 20px;
   position: relative;
   box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.2);
   cursor: pointer;
 }
.shop_item img{
  transition: all 0.3s;
}
 .shop_item:hover img{
   transform: scale(1.1);
 }
 .shop_vip{
   position: absolute;
   top: 0;
   right: 0;
   transform: translateY(-100%);
   width: 57px;
   height: 29px;
   background: #FFAA00;
   border-radius: 8px 0px 0px 0px;
   font-size: 12px;
   color: #FFFFFF;
 }
 .shop_cont{
   width: 100%;
   height: 50px;
   /*position: absolute;*/
   /*bottom: 0;*/
   /*left: 0;*/
   padding: 6px 8px 5px 8px;
   box-sizing: border-box;
   background: rgba(255, 255, 255,1);
 }
 .shop_item:nth-child(5n) {
   margin-right: 0;
 }


 .right_goods{
   margin-left: 18px;
   width: 1030px;
   /*height: 620px;*/
   background: #FFFFFF;
   box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   scrollbar-width: none; /* Firefox */
   -ms-overflow-style: none; /* IE 10+ */
   box-sizing: border-box;
   padding: 0 20px;
   padding-top: 15px;
 }
 .right_cate{
   scrollbar-width: none; /* Firefox */
   -ms-overflow-style: none; /* IE 10+ */
   box-sizing: border-box;
   overflow-x: auto;
   padding: 14px 26px;
   white-space: nowrap;
 }
 .right_item{
   font-size: 16px;
   color: #000000;
   transition: all 0.3s;
   margin-right: 20px;
   margin-bottom: 10px;
 }

 .goods_item{
   width: 185px;
   height: 260px;
   background: #FFFFFF;
   box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   margin-bottom: 15px;
   margin-right: 15px;
 }
 .goods_item:nth-child(5n) {
   margin-right: 0;
 }
 .goods_item .img{
   width: 185px;
   height: 160px;
   background: #f1f1f1;
   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
   border-radius: 4px 4px 4px 4px;
   overflow: hidden;
 }
 .goods_item .img img{
   transition: all 0.3s;
   width: 100%;
   height: 100%;
 }
 .goods_item:hover .img img{
   transform: scale(1.1);
 }


 .swiper_warp{
   width: 1200px;
   margin: 0 auto;
 }
 .swiper{
   /*width: 100%;*/
   width: 900px;
 }
 .notice{
   width: 280px;
   height: 237px;
   background: #FFFFFF;
   box-sizing: border-box;
   padding-top: 10px;
 }
 .newsList {
   margin: 0 auto;
   height: 187px;
   overflow: hidden;
 }
 .anim {
   transition: all 1.5s;
   margin-top: -48px;
 }
 #con1 li {
   color: #333333;
   list-style: none;
   cursor: pointer;
   transition: all 0.3s;
 }
 #con1 li:hover{
   text-decoration: none;
   color: #3765FF;
 }
 .news_item{
   width: 258px;
   position: relative;
   font-size: 14px;
   padding: 14px 0;
   position: relative;
   border-bottom: 1px solid #BFBFBF;
   padding-left: 23px;
 }
 .news_item:before{
   content: '';
   width: 8px;
   height: 8px;
   background: #1572F9;
   border-radius: 50%;
   position: absolute;
   left: 0;
   top: 50%;
   transform: translateY(-50%);
 }
 .notice_border{
   width: 262px;
   height: 1px;
   border: 1px solid #2AA5E3;
   margin-top: 10px;
 }
</style>
<style>
  .el-notification,.right{
    border: 1px solid #1572F9 !important;
  }
  .popper1{
    border: 1px solid #1572F9 !important;
  }
  .popper1 .popper__arrow{
    border-right-color: #1572F9 !important;
  }
  /*分类箭头*/
  .el-tabs__nav-prev .el-icon-arrow-left,.el-tabs__nav-next .el-icon-arrow-right {
    font-size: 25px !important;
    padding-top: 9px !important;
    font-weight: bold;
    color: #1572F9;
  }
</style>
