<template>
  <div>
    <div class="e_warp" v-if="showApply" style="display: flex;flex-direction: column" v-loading="showLoading">
      <div class="e_item dif alc dif alc">
        <div class="e_left">企业入驻:</div>
        <div class="e_select" >
          <el-select v-model="obj.in_type" placeholder="请选择入驻类型">
            <el-option
                v-for="item in inTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">企业分类:</div>
        <div class="e_select" >
          <el-select v-model="obj.firm_type" placeholder="请选择企业分类">
            <el-option
                v-for="item in firmTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">产品分类:</div>
        <div class="e_select" >
          <el-select v-model="obj.chanpin_type" placeholder="请选择">
            <el-option
                v-for="item in chanpinTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">企业名称:</div>
        <div class="e_select">
          <el-input placeholder="请输入企业名称" v-model="obj.name"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">企业电话:</div>
        <div class="e_select">
          <el-input placeholder="请输入联系方式" v-model="obj.mobile" maxlength="11"></el-input>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">入驻区域:</div>
        <div class="e_select">
          <el-cascader
              size="large"
              :options="citys"
              v-model="selectedOptions">
          </el-cascader>
        </div>
      </div>
      <div class="e_item dif alc dif alc">
        <div class="e_left">详细地址:</div>
        <div class="e_select">
          <el-input placeholder="请输入详细地址" v-model="obj.info_address"></el-input>
        </div>
      </div>
      <div class="dif" style="margin-top: 17px;">
        <p class="fs16 col000" style="margin-right: 28px;margin-left: 31px;">营业执照以及公司资质</p>
        <div>
          <el-upload
              :action="$store.state.upLoadUrl"
              :headers="{'token': token}"
              :file-list="fileList"
              :on-remove="onRemove"
              :limit="3"
              :on-success="onSuccess"
              :on-exceed="onExceed"
              list-type="picture-card">
            <i class="el-icon-plus" ></i>
          </el-upload>
        </div>
      </div>
      <div class="submit_button difcac colfff pointer" @click="submit()">提交入驻申请</div>

      <!--支付方式选择-->
      <el-dialog
          title="选择支付方式"
          :visible.sync="showPay"
          width="40%"
          top="25vh">
        <div>
          <div class="difcac">
            <div class="pay_item difcac" @click="payType=1" :class="{'active': payType===1}">
              <img src="@/assets/images/icon_zhifubao.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">支付宝支付</span>
              <div class="check" v-if="payType===1"></div>
              <i class="el-icon-check check_icon" v-if="payType===1"></i>
            </div>
            <div class="pay_item difcac mrLft20" @click="payType=2" :class="{'active': payType===2}">
              <img src="@/assets/images/icon_wieixin.png" style="width: 38px;height: 38px;">
              <span class="mrLft10">微信支付</span>
              <div class="check" v-if="payType===2"></div>
              <i class="el-icon-check check_icon" v-if="payType===2"></i>
            </div>
          </div>
          <div class="dife alc mrTop30">
            <el-button @click="showPay=false">取 消</el-button>
            <el-button type="primary" @click="getPayInfo()">支 付</el-button>
          </div>
        </div>
      </el-dialog>
      <!--支付宝支付-->
      <el-dialog :visible.sync="showAlipay" :modal="true" width="350px" :center="true" top="40vh" @close="close2()">
        <div class="difcc">
          <h2 class="tct mrBtm20">支付宝支付</h2>
          <div class="mrTop20 difcac">
            <div>
              <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
            </div>
            <div class="mrLft30">
              <el-button type="info" plain round @click="close2()">重新支付</el-button>
            </div>
          </div>
        </div>
      </el-dialog>

      <!--微信-->
      <el-dialog :visible.sync="showWeChatCode" :modal="true" width="350px" :center="true" top="30vh" @close="close()">
        <div v-if="imgUrl" class="difcc">
          <h2 class="tct mrBtm20">微信支付</h2>
          <img :src="imgUrl" style="width: 200px;height: auto">
          <div class="mrTop20 difcac">
            <div>
              <el-button type="success" plain round @click="paySuccess()">支付完成</el-button>
            </div>
            <div class="mrLft30">
              <el-button type="info" plain round @click="close()">重新支付</el-button>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div v-else class="box bgff" style="height: 90vh;padding: 100px 0;">

      <el-result icon="success" title="提示" :subTitle="info" v-if="info==='您已经是商户了'"></el-result>
      <el-result icon="info" title="提示" :subTitle="info" v-else></el-result>
    </div>
  </div>
</template>

<script>
import { provinceAndCityData, regionData, provinceAndCityDataPlus, regionDataPlus, CodeToText, TextToCode } from 'element-china-area-data'
export default {
  name: "enterprise",
  data() {
    return {
      info: '',//提示内容
      showApply: true,//显示填写表单
      showLoading: false,
      token: '',
      citys: regionData,
      selectedOptions: [],//选中的地址
      inTypes: [ //入驻类型
        {label: '金牌企业',value: 1},
        {label: '普通企业',value: 2},
      ],
      firmTypes: [ //企业分类
        {label: '供应商',value: 1},
        {label: '采购商',value: 2},
      ],
      chanpinTypes: [ //产品分类
        {label: '工业用品',value: 1},
        {label: '日用品',value: 2},
      ],
      obj: {
        in_type: '',//入驻类型
        firm_type: '',//企业分类
        chanpin_type: '',//产品分类
        name: '',
        mobile: '',
        license_images: '',//营业执照
        address: '',//地址
        info_address: '',//详细地址
        sheng_code: '',
        shi_code: '',
        qu_code: '',
      },
      fileList: [],//营业执照
      order_id: '',//支付id

      showPay: false,
      payType: 1,//1支付宝支付 2微信支付
      showAlipay: false,
      showWeChatCode: false,
      imgUrl: '',//微信二维码
    }
  },
  created() {
    this.token = localStorage.getItem('hbzg')
    this.getInfo()
  },
  methods: {
    //提交入驻申请
    submit() {
      // 上传的图片
      let imgs = []
      for(let item of this.fileList) {
        if(item.response.code==1) {
          imgs.push(item.response.data.url)
        }
      }
      // 地址
      let address = ''
      if(this.selectedOptions.length>0) {
        this.obj.sheng_code =  this.selectedOptions[0]
        this.obj.shi_code =  this.selectedOptions[1]
        this.obj.qu_code =  this.selectedOptions[2]
        address = `${CodeToText[this.selectedOptions[0]]}-${CodeToText[this.selectedOptions[1]]}-${CodeToText[this.selectedOptions[2]]}`
      }
      let data = this.obj
      data.license_images = imgs.join(',')
      data.address = address
      this.showLoading = true
      this.$axios("POST","/mchs/enter",data)
      .then(res => {
        this.showLoading = false
        if(res.code===1) {
          if(res.data.is_pay==0) { //不需要支付
            this.$alert('申请成功，请等待审核')
            .then(e => {
              this.$router.go(0)
            }).catch(e => {
              this.$router.go(0)
            })
          }else { //需要支付
            this.order_id = res.data.order_id
            this.showPay = true
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //金牌入住 获取支付参数
    getPayInfo() {
      this.showLoading = true
      this.showPay = false
      this.$axios("POST","/Mchs/place",{
        order_id: this.order_id,
        pay_type: this.payType
      }).then(res => {
        this.showLoading = true
        if(res.code===1) {
          if(this.payType===1) { //支付宝支付
            this.showAlipay = true
            // 打开支付宝form表单
            const div = document.createElement('div')
            div.innerHTML = res.data
            document.body.appendChild(div)
            document.forms[0].acceptCharset = "UTF-8"
            document.forms[0].target = "_blank"
            document.forms[0].submit()
          }else { //微信支付
            this.showWeChatCode = true
            this.imgUrl = res.data
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //上传图片相关
    onSuccess(res, file, fileList) {
      this.fileList = fileList
      console.log(fileList);
    },
    onRemove(file,fileList) {
      this.fileList = fileList
    },
    onExceed() {
      this.$alert('最多上传3张照片',{type: 'info'})
    },
    close() { //微信支付关闭弹窗
      this.imgUrl = ''
      this.showWeChatCode = false
    },
    close2() { //支付宝支付关闭弹窗
      this.showAlipay = false
      this.$router.go(0)
    },
    paySuccess() { //点击支付成功
      this.$router.go(0)
    },

    //获取填写内容
    getInfo() {
      this.showLoading = true
      this.$axios("POST","/mchs/checkSub",{})
          .then(res => {
            this.showLoading = false
            if(res.code===1) {
              if(!res.data) {
                this.showApply = true
              }else {
                this.obj.in_type = res.data.info.in_type*1
                this.obj.firm_type = res.data.info.firm_type*1
                this.obj.chanpin_type = res.data.info.chanpin_type*1
                this.obj.name = res.data.info.name
                this.obj.mobile = res.data.info.mobile
                this.obj.sheng_code = res.data.info.sheng_code
                this.obj.shi_code = res.data.info.shi_code
                this.obj.qu_code = res.data.info.qu_code
                this.obj.address = res.data.info.address
                this.obj.info_address = res.data.info.info_address
                // 图片回显
                let arr = res.data.info.license_images.split(',')
                for(let value of arr) {
                  this.fileList.push({
                    url: this.$store.state.imgUrl + value,
                    response: {
                      code: 1,
                      data: {url: value}
                    }
                  })
                }
                // 地址回显
                let addressStr = res.data.info.address.split('-')
                this.selectedOptions = [
                  TextToCode[addressStr[0]].code,
                  TextToCode[addressStr[0]][addressStr[1]].code,
                  TextToCode[addressStr[0]][addressStr[1]][addressStr[2]].code,
                ]
                this.showApply = true
                this.$alert(res.msg,{type:"info"})
              }
            }else {
              this.showApply = false
              this.info = res.msg
            }
          })
    },
  }
}
</script>

<style scoped>
  .e_warp{
    width: 1114px;
    height: 803px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding-left: 36px;
    padding-top: 49px;
  }
  .e_left{
    font-size: 16px;
    color: #000000;
    margin-right: 28px;
  }
  .e_item{
    border-bottom: 1px dashed #EEEEEE;
    padding: 13px 61px;
    box-sizing: border-box;
    width: 490px;
  }
  .e_select ::v-deep.el-input__inner{
    width: 260px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #D2D2D2;
    box-sizing: border-box;
    padding: 0 17px;
    font-size: 16px;
  }
  .submit_button{
    width: 443px;
    height: 65px;
    background: #007AFF;
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    margin-top: 45px;
    transition: all 0.3s;
  }
  .submit_button:hover{
    background: #3A53FF;
  }



  .pay_item{
    width: 236px;
    height: 69px;
    background: #FFFFFF;
    border-radius: 6px 6px 6px 6px;
    border: 2px solid #868686;
    cursor: pointer;
    font-size: 28px;
    color: #383D42;
    overflow: hidden;
    position: relative;
  }
  .pay_item.active{
    border: 2px solid #337DF7;
    color: #337DF7;
  }
  .check{
    width: 41px;
    height: 41px;
    background-color: #3765FF;
    position: absolute;
    right: -20px;
    bottom: -20px;
    transform: rotate(45deg);
  }
  .check_icon{
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 18px;
    color: #ffffff;
    z-index: 22;
  }
</style>