<template>
  <div>
    <!--<navbar></navbar>-->
    <!--轮播图-->
    <div class="swiper_warp difb alc">
      <div class="swiper">
        <!--<el-carousel height="31vw">-->
        <el-carousel height="237px">
          <el-carousel-item v-for="item in swiper" :key="item.id">
            <img :src="item.slideimage" class="w100 h100 bgcb" @click="goSwiper(item)">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--新  公告-->
      <div class="notice">
        <!--公告-->
        <div class="difb alc" style="padding-left: 10px;padding-right: 32px;">
          <div class="dif alc">
            <img src="@/assets/images/icon_gonggao.png" style="width: 28px;height: 28px;margin-right: 5px;">
            <div class="fs20" style="color: #1572F9">最新成交</div>
          </div>
          <!--<img src="@/assets/images/icon_gengduo.png" style="width: 12px;height: 12px;">-->
        </div>
        <div class="notice_border"></div>
        <div class="newsList" @mouseover="mouseOver" @mouseout="mouseOut">
          <ul id="con1" ref="con1" :class="{ anim: animate }">
            <li v-for="(item, key) in items" class="news_item one_overflow">
              {{item}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--商品列表-->
    <div class="dif alc box" style="width: 1000px;margin: 16px auto;">
      <div class="top_item" v-for="(item,index) in topCate" @click="selectTop(item,index)"
           :class="{'active': topCurrent===index}"
      >{{item.name}}</div>
    </div>
    <!--商品列表-->
    <div class="mr0At difc" style="margin-top: 22px;" v-loading="showLoding">
      <!--左侧分类-->
      <div class="left_cate">
        <div class="left_item tct " v-for="(item,index) in leftCate"
             :class="{'active': leftCurrent===index}" @click="selectLeftCate(item,index)"
             :key="index" v-popover:popover>
          <el-popover
              placement="right"
              width="350"
              trigger="hover"
              v-model="item.check"
          >
            <div slot="reference" class="tct">{{item.name}}</div>
            <!--内容-->
            <div class="dif difw box" style="padding: 5px 10px;overflow:auto;max-height: 400px;">
              <div class="pointer hover right_item" @click="selectRight(index2,index)" :class="{'active': rightCurrent===index2 && leftCurrent===index}"
                   v-for="(item2,index2) in item.san_arr" :key="index2">{{item2.name}}</div>
            </div>
          </el-popover>
        </div>


      </div>
      <!--右侧商品-->
      <div class="right_goods">
        <!--右侧分类-->
        <!--新-->
        <div class="dif difw" v-if="rightGoods.length>0">
          <div class="goods_item pointer" v-for="(item,index) in rightGoods" :key="index" @click="goDetail(item)">
            <div class="img">
              <img :src="item.image" :alt="item.name" :title="item.name">
            </div>
            <div class="box" style="padding: 10px;">
              <div class="one_overflow fz16">{{item.name}}</div>
              <div class="one_overflow fs12 mrBtm10"
                   style="color: #A0A0A0;margin-top: 5px;">
                销量：{{item.sales}} 库存：{{item.stock}}
              </div>
              <div class="bold" style="color: #F75C5C">
                <span class="fs14">￥</span>
                <span class="fs18">{{item.price}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="box" style="padding-top: 100px;">
          <el-result icon="info" title="提示" subTitle="暂无商品"></el-result>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="difcac home_page" style="margin: 38px 0 50px 0;">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page="page"
          @current-change="changeSize">
      </el-pagination>
    </div>

    <!--系统公告-->
    <el-dialog
        title="系统公告"
        :visible.sync="showAffiche"
        width="30%"
        center>
      <div v-html="affiche" style="overflow-y: auto;max-height: 60vh"></div>
    </el-dialog>
  </div>
</template>

<script>

import Navbar from "@/components/common/navbar";
export default {
  name: 'DailyShopSearch',
  components: {
    Navbar
  },
  data() {
    return {
      topCate: [
        {id: 0,name: '平台推荐'},
        {id: 1,name: '特价专区'},
      ],
      topCurrent: 0,
      leftCurrent: 0,//当前选中的左侧分类
      leftCate: [
        {name: '行业分类'},{name: '建筑'},
      ],//左侧分类
      rightCurrent: 0,//当前选中的右侧分类
      rightGoods: [],//右侧商品
      showLoding: false,//显示加载
      swiper: [],//轮播图
      limit: 30,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数

      timer: null,
      items: [],
      animate: false,

      affiche: '',//系统公告
      showAffiche: false,//显示系统公告
    }
  },
  methods: {
    //获取公告
    getAffiche() {
      this.$axios("POST","/index/getConfig",{name: 'affiche'})
          .then(res => {
            if(res.code==1) {
              let isShowAffiche = sessionStorage.getItem('isShowAffiche')
              if(!isShowAffiche) {
                this.showAffiche = true
                sessionStorage.setItem('isShowAffiche',1)
              }
              this.affiche = res.data
            }
          })
    },
    //点击轮播图跳转
    goSwiper(item) {
      if(item.is_jump==2) {
        this.$router.push({
          path: '/shopDetail',
          query: {id: item.mch_id}
        })
      }
    },
    //获取轮播图
    getSwiper() {
      this.$axios("POST","/index/getRotationMap",{module: 10})
          .then(res => {
            if(res.code===1) this.swiper = res.data
          })
    },
    //选择上方分类
    selectTop(item,index) {
      this.page = 1
      this.topCurrent = index
      this.getGoodsList()
    },
    //选择左侧分类
    selectLeftCate(item,index) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = index
      item.check = true
      return
      this.rightCurrent = 0
      this.page = 1
      this.getGoodsList()
    },
    //选择右侧分类
    selectRight(index,pindex) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = pindex
      this.rightCurrent = index
      this.rightGoods = 0
      this.page = 1
      this.getGoodsList()
    },
    //获取商城分类
    getGoodCate() {
      this.$axios("POST","/Goods/getGoodCate",{})
          .then(res => {
            if(res.code===1) {
              this.leftCate = res.data
              this.getGoodsList();
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //获取商品列表
    getGoodsList() {
      this.showLoding = true
      let data = {
        chanpin_type: 2,
        cate_id: this.leftCate[this.leftCurrent].san_arr[this.rightCurrent].id,
        page: this.page,
        limit: this.limit,
        is_special: this.topCate[this.topCurrent].id
      }
      this.$axios("POST","/Goods/getGoodList",data)
          .then(res=>{
            this.showLoding = false
            if(res.code===1) {
              this.rightGoods = res.data.list
              this.allPage = Math.ceil(res.data.total/this.limit)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getGoodsList();
    },
    //商品详情
    goDetail(item) {
      let url = this.$router.resolve({
        path: '/goods',
        query: {
          id: item.id
        }
      })
      window.open(url.href)
    },

    //初始化动画
    initAnime() {
      this.$axios("POST","/common/getPostNewNotice",{})
          .then(res => {
            if(res.code==1) {
              for(let item of res.data) {
                this.items.push(item.text)
              }
              if(res.data.length>4) {
                this.timer=setInterval(this.scroll, 2000);
              }
            }
          })
    },
    // 开始滚动
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        this.items.push(this.items[0]);
        this.items.shift();
        this.animate = false; // margin-top 为0 的时候取消过渡动画
      }, 1500);
    },
    // 鼠标移入 暂停动画
    mouseOver(){
      clearInterval(this.timer)
    },
    // 鼠标移出 重新开始动画
    mouseOut(){
      this.timer=setInterval(this.scroll, 2000);
    }
  },
  created() {
    this.getAffiche();
    this.getSwiper(); //获取轮播图
    this.initAnime();
    this.getGoodCate() //获取商城分类
  }
}
</script>

<style scoped>
/*.swiper{*/
/*  !*width: 100%;*!*/
/*  width: 1200px;*/
/*  margin: 0 auto;*/
/*}*/
.left_cate{
  box-sizing: border-box;
  padding: 18px 0;
  width: 150px;
  /*height: 888px;*/
  height: 620px;
  background: #FFFFFF;
  overflow-y: auto;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.left_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.left_item{
  width: 100%;
  min-height: 34px;
  box-sizing: border-box;
  padding: 8px 4px;
  font-size: 14px;
  color: #000000;
  transition: all 0.3s;
  cursor: pointer;
}
.left_item:hover{
  font-weight: bold;
}
.left_item.active{
  color: #ffffff;
  background-color: #0B8EFE;
}
/*.right_goods{
  margin-left: 18px;
  width: 1200px;
  height: 888px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  padding: 0 20px;
}*/
.right_goods::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/*.right_cate{
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  overflow-x: auto;
  padding: 28px 26px;
  white-space: nowrap;
}*/
.right_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.right_item:nth-last-child(1) {
  margin-right: 0;
}
/*.right_item{
  display: inline-block;
  font-size: 28px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 90px;
}*/
.right_item.active{
  color: #1572F9;
}
/* .goods_item{
   width: 275px;
   height: 376px;
   background: #FFFFFF;
   box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   margin-bottom: 25px;
   margin-right: 20px;
 }
 .goods_item:nth-child(4n) {
   margin-right: 0;
 }
 .goods_item .img{
   width: 275px;
   height: 241px;
   background: #f1f1f1;
   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
   border-radius: 4px 4px 4px 4px;
   overflow: hidden;
 }
 .goods_item .img img{
   transition: all 0.3s;
   width: 100%;
   height: 100%;
 }
 .goods_item:hover .img img{
   transform: scale(1.1);
 }*/
.home_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}




.right_goods{
  margin-left: 18px;
  width: 1030px;
  /*height: 620px;*/
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 15px;
}
.right_cate{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  overflow-x: auto;
  padding: 14px 26px;
  white-space: nowrap;
}
.right_item{
  font-size: 22px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 20px;
}
.right_item:nth-child(5n) {
  margin-right: 0;
}
.goods_item{
  width: 185px;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 15px;
  margin-right: 15px;
}
.goods_item:nth-child(5n) {
  margin-right: 0;
}
.goods_item .img{
  width: 185px;
  height: 160px;
  background: #f1f1f1;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
}
.goods_item .img img{
  transition: all 0.3s;
  width: 100%;
  height: 100%;
}
.goods_item:hover .img img{
  transform: scale(1.1);
}


.swiper_warp{
  width: 1200px;
  margin: 0 auto;
}
.swiper{
  /*width: 100%;*/
  width: 900px;
}
.notice{
  width: 280px;
  height: 237px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 10px;
}
.newsList {
  margin: 0 auto;
  height: 187px;
  overflow: hidden;
}
.anim {
  transition: all 1.5s;
  margin-top: -48px;
}
#con1 li {
  color: #333333;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
}
#con1 li:hover{
  text-decoration: none;
  color: #3765FF;
}
.news_item{
  width: 258px;
  position: relative;
  font-size: 14px;
  padding: 14px 0;
  position: relative;
  border-bottom: 1px solid #BFBFBF;
  padding-left: 23px;
}
.news_item:before{
  content: '';
  width: 8px;
  height: 8px;
  background: #1572F9;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.notice_border{
  width: 262px;
  height: 1px;
  border: 1px solid #2AA5E3;
  margin-top: 10px;
}

.top_item{
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  margin-right: 79px;
  transition: all 0.3s;
}
.top_item.active,.top_item:hover{
  color: #1572F9;
  font-weight: bold;
}
</style>
