import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "@/views/Login";
import submitPay from "@/views/submitPay";
import WantToBuy from "@/views/WantToBuy/WantToBuy";
import buyDetail from "@/views/WantToBuy/buyDetail";
import WantToBuyCenter from "@/views/WantToBuy/WantToBuyCenter";
import InsWorksCenter from "@/views/InsWorksCenter/InsWorksCenter";
import LogisticsCenter from "@/views/Logistics/LogisticsCenter";
import LogisticsSearch from "@/views/Logistics/LogisticsSearch";
import LogisticsDetail from "@/views/Logistics/LogisticsDetail";
import RecruitCenter from "@/views/Recruit/RecruitCenter";
import RecruitSearch from "@/views/Recruit/RecruitSearch";
import RecruitDetail from "@/views/Recruit/RecruitDetail";
import IndustryCenter from "@/views/IndustryShop/IndustryCenter";
import IndustryDetail from "@/views/IndustryShop/IndustryDetail";
import IndustrySearch from "@/views/IndustryShop/IndustrySearch";
import JobWantedSearch from "@/views/JobWanted/JobWantedSearch";
import JobWantedCenter from "@/views/JobWanted/JobWantedCenter";
import JobWantedDetail from "@/views/JobWanted/JobWantedDetail";
import goodsDetail from "@/views/GoodsDetail/goodsDetail";
import DailyShopCenter from "@/views/DailyShop/DailyShopCenter";
import SecondHandCenter from "@/views/SecondHand/SecondHandCenter";
import SecondHandSearch from "@/views/SecondHand/SecondHandSearch";
import SecondHandDetail from "@/views/SecondHand/SecondHandDetail";
import message from "@/views/GoodsDetail/message";
import payOrder from "@/views/order/payOrder";
import shopDetail from "@/views/shopDetail/shopDetail";
import myCollect from "@/views/meCenter/myCollect";
import cart from "@/views/meCenter/cart";
import IntegralMall from "@/views/IntegralMall/IntegralMall";
import IntegralDetail from "@/views/IntegralMall/IntegralDetail";
import payIntegral from "@/views/order/payIntegral";
import meCenter from "@/views/meCenter/meCenter";
import myInfo from "@/views/meCenter/right/myInfo";
import myOrder from "@/views/meCenter/right/myOrder/myOrder";
import orderList from "@/views/meCenter/right/myOrder/orderList";
import iorderList from "@/views/meCenter/right/integralOrder/orderList";
import after from "@/views/meCenter/right/myOrder/after";
import orderDetail from "@/views/meCenter/right/myOrder/orderDetail";
import iorderDetail from "@/views/meCenter/right/integralOrder/orderDetail";
import offer from "@/views/meCenter/right/offer/offer";
import offerDetail from "@/views/meCenter/right/offer/offerDetail";
import addressList from "@/views/meCenter/right/address/addressList";
import enterprise from "@/views/meCenter/right/enterprise";
import bidding from "@/views/meCenter/right/bidding";
import applyAgent from "@/views/meCenter/right/applyAgent";
import vip from "@/views/meCenter/right/vip";
import addAddress from "@/views/meCenter/right/address/addAddress";
import DailyShopSearch from "@/views/DailyShop/DailyShopSearch";
import retrunSubmit from "@/views/meCenter/right/myOrder/retrunSubmit";
import afterDetail from "@/views/meCenter/right/myOrder/afterDetail";
import search from "@/views/search/search";
import InsWorksSearch from "@/views/InsWorksCenter/InsWorksSearch";
import InsWorksDetail from "@/views/InsWorksCenter/InsWorksDetail";
import integralOrder from "@/views/order/integralOrder";
import contact from "@/views/meCenter/right/contact";
import deduction from "@/views/meCenter/right/deduction";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {title: '慧邦中工'}
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {title: '登录'}
  },
  {
    path: '/submitpay',
    name: 'submitPay',
    component: submitPay,
    meta: {title: '支付'}
  },
  {
    path: '/wangtobuy',
    name: 'WantToBuyCenter',
    component: WantToBuyCenter,
    redirect: '/wangtobuy/search',
    meta: {title: '工程设备'},
    children: [
      {
        path: 'search',
        name: 'WantToBuy',
        component: WantToBuy
      },
      {
        path: 'detail',
        name: 'buyDetail',
        component: buyDetail
      },
    ]
  },
  {
    path: '/insworks',
    name: 'InsWorksCenter',
    component: InsWorksCenter,
    redirect: '/insworks/search',
    meta: {title: '安装工程'},
    children: [
      {
        path: 'search',
        name: 'InsWorksSearch',
        component: InsWorksSearch
      },
      {
        path: 'detail',
        name: 'InsWorksDetail',
        component: InsWorksDetail
      },
    ]
  },
  {
    path: '/logistics',
    name: 'LogisticsCenter',
    component: LogisticsCenter,
    redirect: '/logistics/search',
    meta: {title: '物流货运'},
    children: [
      {
        path: 'search',
        name: 'LogisticsSearch',
        component: LogisticsSearch
      },
      {
        path: 'detail',
        name: 'LogisticsDetail',
        component: LogisticsDetail
      },
    ]
  },
  {
    path: '/recruit',
    name: 'RecruitCenter',
    component: RecruitCenter,
    redirect: '/recruit/search',
    meta: {title: '人才招聘'},
    children: [
      {
        path: 'search',
        name: 'RecruitSearch',
        component: RecruitSearch
      },
      {
        path: 'detail',
        name: 'RecruitDetail',
        component: RecruitDetail
      },
    ]
  },
  {
    path: '/jobwanted',
    name: 'JobWantedCenter',
    component: JobWantedCenter,
    redirect: '/jobwanted/search',
    meta: {title: '求职信息'},
    children: [
      {
        path: 'search',
        name: 'JobWantedSearch',
        component: JobWantedSearch
      },
      {
        path: 'detail',
        name: 'JobWantedDetail',
        component: JobWantedDetail
      },
    ]
  },
  {
    path: '/secondhand',
    name: 'SecondHandCenter',
    component: SecondHandCenter,
    redirect: '/secondhand/search',
    meta: {title: '二手设备'},
    children: [
      {
        path: 'search',
        name: 'SecondHandSearch',
        component: SecondHandSearch
      },
      {
        path: 'detail',
        name: 'SecondHandDetail',
        component: SecondHandDetail
      },
    ]
  },
  {
    path: '/industry',
    name: 'IndustryCenter',
    component: IndustryCenter,
    redirect: '/industry/search',
    meta: {title: '工业用品商城'},
    children: [
      {
        path: 'search',
        name: 'IndustrySearch',
        component: IndustrySearch
      },
    ]
  },
  {
    path: '/daily',
    name: 'DailyShopCenter',
    component: DailyShopCenter,
    redirect: '/daily/search',
    meta: {title: '日用品商城'},
    children: [
      {
        path: 'search',
        name: 'DailyShopSearch',
        component: DailyShopSearch,
       },
    ]
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: orderDetail,
    meta: {title: '订单详情'},
  },
  {
    path: '/iorderDetail',
    name: 'iorderDetail',
    component: iorderDetail,
    meta: {title: '订单详情'},
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta: {title: '搜索'},
  },
  {
    path: '/afterDetail',
    name: 'afterDetail',
    component: afterDetail,
    meta: {title: '售后详情'},
  },
  {
    path: '/me',
    name: 'meCenter',
    component: meCenter,
    redirect: '/me/myInfo',
    meta: {title: '个人中心'},
    children: [
      {
        path: 'myInfo',
        name: 'myInfo',
        component: myInfo,
        meta: {title: '个人中心'},
      },
      {
        path: 'myOrder',
        name: 'myOrder',
        component: myOrder,
        children: [
          {
            path: '',
            name: 'orderList',
            component: orderList,
            meta: {title: '我的订单'},
          },
          {
            path: 'after',
            name: 'after',
            component: after,
            meta: {title: '申请售后'},
          },

          {
            path: 'returnSubmit',
            name: 'retrunSubmit',
            component: retrunSubmit,
            meta: {title: '填写退货单'},
          },
        ]
      },
      {
        path: 'integralOrder',
        name: 'integralOrder',
        component: iorderList,
        children: [
          {
            path: '',
            name: 'orderList',
            component: iorderList,
            meta: {title: '积分订单'},
          },
        ]
      },
      {
        path: 'offer',
        name: 'offer',
        component: offer,
        meta: {title: '报价表'},
      },
      {
        path: 'address',
        name: 'addressList',
        component: addressList,
        meta: {title: '收货地址'},
      },
      {
        path: 'addAddress',
        name: 'addAddress',
        component: addAddress,
        meta: {title: '添加收货地址'},
      },
      {
        path: 'enterprise',
        name: 'enterprise',
        component: enterprise,
        meta: {title: '企业入驻'},
      },
      {
        path: 'bidding',
        name: 'bidding',
        component: bidding,
        meta: {title: '我的招标'},
      },
      {
        path: 'applyAgent',
        name: 'applyAgent',
        component: applyAgent,
        meta: {title: '代理申请'},
      },
      {
        path: 'vip',
        name: 'vip',
        component: vip,
        meta: {title: '开通VIP'},
      },
      {
        path: 'deduction',
        name: 'deduction',
        component: deduction,
        meta: {title: '抵扣包'},
      },
      {
        path: 'contact',
        name: 'contact',
        component: contact,
        meta: {title: '联系客服'},
      }
    ]
  },
  {
    path: '/offerDetail',
    name: 'offerDetail',
    component: offerDetail,
    meta: {title: '报价详情'},
  },
  {
    path: '/goods',
    name: 'goodsDetail',
    component: goodsDetail,
    meta: {title: '商品详情'},
  },
  {
    path: '/message',
    name: 'message',
    component: message,
    meta: {title: '留言'},
  },
  {
    path: '/payOrder',
    name: 'payOrder',
    component: payOrder,
    meta: {title: '提交订单'},
  },
  {
    path: '/integralOrder',
    name: 'integralOrder',
    component: integralOrder,
    meta: {title: '积分订单'},
  },
  {
    path: '/shopDetail',
    name: 'shopDetail',
    component: shopDetail,
    meta: {title: '店铺详情'},
  },
  {
    path: '/myCollect',
    name: 'myCollect',
    component: myCollect,
    meta: {title: '我的收藏'},
  },
  {
    path: '/cart',
    name: 'cart',
    component: cart,
    meta: {title: '购物车'},
  },
  {
    path: '/integralMall',
    name: 'IntegralMall',
    component: IntegralMall,
    meta: {title: '积分兑换'},
  },
  {
    path: '/integralDetail',
    name: 'IntegralDetail',
    component: IntegralDetail,
    meta: {title: '积分商品详情'},
  },
  {
    path: '/payIntegral',
    name: 'payIntegral',
    component: payIntegral,
    meta: {title: '积分订单'},
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
