<template>
  <div style="width: 1200px;margin: 0 auto;padding-top: 73px;padding-bottom: 61px;">
    <!--标题-->
    <h1 class="fs32 col000 one_overflow">标题：{{info.title}}</h1>
    <div class="difb alc" style="margin-top: 57px;">
      <p class="fs18" style="color: #969696">时间：{{info.createtime_str}}</p>
    </div>
    <div class="border"></div>
    <!--内容-->
    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      联系人：{{info.linkman}}
    </div>
    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      联系方式：{{info.contact_way}}
      <span class="fs16  col666 break mrLft20" style="line-height: 1.4em;">
        联系我时请说是在慧邦中工平台看到的
      </span>
    </div>

    <div class="fs18 col000 break " style="line-height: 1.4em;margin-bottom: 5px;">
      电子邮件：{{info.mail}}
      <span class="fs16  col666 break mrLft20" style="line-height: 1.4em;">
         报价请传邮箱,报价单总价请写在邮箱标题上面方便观看
      </span>
    </div>
    <div class="fs18 col000 break" style="line-height: 1.4em;">
      描述：{{info.describe}}
    </div>
    <!--图片-->
    <div class="dif difw detail_img_warp mrTop20">
      <img v-for="(item,index) in info.used_images" :src="item" :key="index" @click="showImg(item)">
    </div>
    <div class="border2"></div>
    <!--联系方式-->
    <div class="contact posr" v-if="false">
      <!--背景图-->
      <img src="@/assets/images/icon_bjbj.png" class="w100 h100">
      <!--内容-->
      <div class="contact_warp">
        <h2 class="fs20 box" style="padding-left: 80px;">报价请传邮箱,报价单总价请写在邮箱标题上面方便观看</h2>
        <div class="contact_item difb alc" style="margin-top: 33px;">
          <div>联系人：</div>
          <div style="color: #969696">{{info.linkman}}</div>
        </div>
        <div class="contact_item difb alc">
          <div>联系方式：</div>
          <div style="color: #969696" class="dif alc">
            {{info.contact_way}}
            <img src="@/assets/images/icon_dianhua.png" style="width: 24px;height: 24px;margin-left: 11px;">
          </div>
        </div>
        <div class="contact_item dife alc" style="color: #969696">
          联系我时请说是在慧邦中工平台看到的
        </div>
        <div class="contact_item difb alc">
          <div style="color: #414141">电子邮件：</div>
          <div style="color: #969696">{{info.mail}}</div>
        </div>
      </div>
    </div>
    <!--操作按钮-->
    <div class="difb alc" style="margin-top: 124px;">
      <!--未收藏-->
      <div class="dif alc pointer" v-if="info.is_collect==0" @click="collect()">
        <img src="@/assets/images/icon_shoucang.png" style="width: 24px;height: 24px;margin-right: 12px;">
        <span style="color: #969696;" class="fs18 pointer hover">收藏</span>
      </div>
      <!--已收藏-->
      <div class="dif alc pointer" v-if="info.is_collect==1" @click="collect()">
        <img src="@/assets/images/shoucang2.png" style="width: 24px;height: 24px;margin-right: 12px;">
        <span style="color: #969696;" class="fs18 pointer hover">取消收藏</span>
      </div>
      <!--我要发布-->
      <div class="button difcac" @click="fabu()">
        <img src="@/assets/images/icon_dh.png" style="width: 26px;height: 26px;margin-right: 13px;">
        <span>{{info.contact_way}}</span>
      </div>
    </div>
    <el-dialog :visible.sync="show">
      <img width="100%" :src="imgUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SecondHandDetail",
  data() {
    return {
      id: '',
      info: {
        id: '',
        title: '',
        office_location: '',
        linkman: '',
        contact_way: '',
        mail: '',
        describe: '',
        user_info: {nickname: ''},
        is_collect: '',
        post_salary_text: '',
        degree_level_text: '',
        work_experience_text: '',
        createtime_str: '',
        used_images: []
      },
      imgUrl: '',
      show: false,
    }
  },
  methods: {
    //查看图片
    showImg(item) {
      this.imgUrl = item
      this.show = true
    },
    //我要发布
    fabu() {
      // eventBus.$emit('showFabu',{});
      this.$alert(this.info.contact_way,{title: '联系方式'})
    },
    //收藏
    collect() {
      let data = {
        post_id: this.id,
        type: 6,
        collect_type: this.info.is_collect==1?2:1
      }
      this.$axios("POST","/common/postCollect",data)
          .then(res => {
            if(res.code===1) {
              this.$message.success('操作成功')
              this.getDetail()
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //获取详情
    getDetail() {
      this.$axios("POST","/post/second_hand/getInfo",{
        id: this.id
      },'加载中').then(res => {
        if(res.code===1) {
          this.info = res.data
          console.log(this.info);
        }else {
          this.$alert(res.msg,{type: 'error'})
              .then(eee => {
                window.close()
              })
        }
      })
    },
  },
  created() {
    this.id = this.$route.query.id
    this.getDetail() //获取详情
  }
}
</script>

<style scoped>
.border{
  width: 979px;
  height: 1px;
  background: #EEEEEE;
  margin: 0 auto;
  margin-top: 14px;
  margin-bottom: 30px;
}
.border2{
  width: 1200px;
  height: 8px;
  margin: 14px 0;
  background: #EEEEEE;
}
.contact{
  width: 1200px;
  height: 648px;
  background: #000000;
}
.contact_warp{
  width: 906px;
  height: 443px;
  background: #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
  padding-top: 68px;
}
.contact_item{
  border-bottom: 1px dashed #EEEEEE;
  padding: 19px 148px 17px 59px;
  color: #000000;
  font-size: 18px;
  width: 865px;
  margin: 0 auto;
}
.button{
  width: 272px;
  height: 55px;
  background: #1572F9;
  font-size: 18px;
  color: #ffffff;
  transition: all 0.2s;
  cursor: pointer;
}
.button:hover{
  background: #015bd5;
}
.detail_img_warp img{
  width: 275px;
  height: 241px;
  background: #EEEEEE;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.detail_img_warp img:nth-child(4n){
  margin-right: 0;
}
</style>