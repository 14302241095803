<template>
  <div>
    <!--店铺标题logo-->
    <div class="shop_top">
      <div class="mr0At box difb alc" style="width: 1150px;padding-right: 146px;">
        <div class="dif alc">
          <div class="difcac logo pointer">
            <img :src="info.logo_image" class=" h100" style="width: auto;max-width: 140px">
          </div>
          <div class="fs16 col000 pointer hover">
            <div class="dif alc">
              <p class="hover">{{info.name}}</p>
              <p class="hover mrLft20">联系电话：{{info.service_tel}}</p>
            </div>
            <p class="hover">{{info.address}}{{info.info_address}}</p>
          </div>
        </div>
        <div class="difcac pointer hover message_button" @click="goMessage()">
          <img src="@/assets/images/message.png" style="width: 16px;height: 16px;">
          <p class="fs16 colfff" style="margin-left: 7px;">留言</p>
        </div>
      </div>
    </div>
    <!--轮播图-->
    <div class="swiper_warp difb alc">
      <div class="swiper">
        <!--<el-carousel height="31vw">-->
        <el-carousel height="237px">
          <el-carousel-item v-for="(item,index) in info.bg_image" :key="index">
            <img :src="item" class="w100 h100 bgcb">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--新  公告-->
      <div class="notice">
        <!--公告-->
        <div class="difb alc" style="padding-left: 10px;padding-right: 32px;">
          <div class="dif alc">
            <img src="@/assets/images/icon_gonggao.png" style="width: 28px;height: 28px;margin-right: 5px;">
            <div class="fs20" style="color: #1572F9">最新成交</div>
          </div>
          <!--<img src="@/assets/images/icon_gengduo.png" style="width: 12px;height: 12px;">-->
        </div>
        <div class="notice_border"></div>
        <div class="newsList" @mouseover="mouseOver" @mouseout="mouseOut">
          <ul id="con1" ref="con1" :class="{ anim: animate }">
            <li v-for="(item, key) in items" class="news_item one_overflow">
              {{item}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--商品列表-->
    <div class="mr0At difc" style="margin-top: 22px;" v-loading="showLoding">
      <!--左侧分类-->
      <div class="left_cate" v-if="false">
        <div class="left_item tct difcac" v-for="(item,index) in leftCate"
             :class="{'active': leftCurrent===index}" @click="selectLeftCate(item,index)"
             :key="index" v-popover:popover>
          <el-popover
              placement="right"
              trigger="hover"
              popperClass="popper1"
              width="500"
              v-model="item.check">
            <div slot="reference">{{item.name}}</div>
            <!--内容-->
            <div class="dif difw box" style="padding: 5px 10px;overflow:auto;max-height: 400px;">
              <div class="pointer hover right_item" @click="selectRight(index2,index)" :class="{'active': rightCurrent===index2 && leftCurrent===index}"
                   v-for="(item2,index2) in item.san_list" :key="index2">{{item2.name}}</div>
            </div>
          </el-popover>
        </div>
      </div>
      <!--右侧商品-->
      <div class="right_goods">
        <!--右侧分类-->
        <!-- <div class="right_cate" v-dragscroll v-if="leftCate.length>0">
           <div class="pointer hover right_item" @click="selectRight(index)" :class="{'active': rightCurrent===index}"
                v-for="(item,index) in leftCate[leftCurrent].san_list" :key="index">{{item.name}}</div>
         </div>-->
        <!--商品列表-->
        <!--<div class="dif difw" v-if="rightGoods.length>0">
          <div class="goods_item pointer" v-for="(item,index) in rightGoods" :key="index" @click="goDetail(item)">
            <div class="img">
              <img :src="item.image" :alt="item.name" :title="item.name">
            </div>
            <div class="box" style="padding: 20px;">
              <div class="one_overflow fs18">{{item.name}}</div>
              <div class="one_overflow fs14 mrBtm10"
                   style="color: #A0A0A0;margin-top: 5px;">
                销量：{{item.sales}} 库存：{{item.stock}}
              </div>
              <div class="bold" style="color: #F75C5C">
                <span class="fs18">￥</span>
                <span class="fs24">{{item.price}}</span>
              </div>
            </div>
          </div>
        </div>-->
        <!--新-->
        <div class="dif difw" v-if="rightGoods.length>0">
          <div class="goods_item pointer" v-for="(item,index) in rightGoods" :key="index" @click="goDetail(item)">
            <div class="img">
              <img :src="item.image" :alt="item.name" :title="item.name">
            </div>
            <div class="box" style="padding: 10px;">
              <div class="one_overflow fz16">{{item.name}}</div>
              <div class="one_overflow fs12 mrBtm10"
                   style="color: #A0A0A0;margin-top: 5px;">
                销量：{{item.sales}} 库存：{{item.stock}}
              </div>
              <div class="bold" style="color: #F75C5C">
                <span class="fs14">￥</span>
                <span class="fs18">{{item.price}}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="box" style="padding-top: 100px;">
          <el-result icon="info" title="提示" subTitle="暂无商品"></el-result>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="difcac home_page" style="margin: 38px 0 50px 0;">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page="page"
          @current-change="changeSize">
      </el-pagination>
    </div>
    <!--弹窗提示-->
    <transition enter-active-class="animation-scale-up" leave-active-class="animation-scale-up reverse">
      <div class="pay_bg difcac" v-if="showPay" @click="showPay=false">
        <div class="pay_warp" @click.stop>
          <p class="tct fs32 col000">提示</p>
          <p class="fs32 tct" style="color: #414141;margin-top: 29px;">您需要消耗<span style="color: #F75C5C">2元</span>，才可以继续浏览</p>
          <div class="difcac" style="margin-top: 56px;">
            <div class="button1 difcac" @click="showPay=false">取消</div>
            <div class="button2 difcac" @click="goPay()">确定</div>
          </div>
          <!--<p class="tct fs24 col000" style="margin-top: 42px;">-->
          <!--  <span style="color: #FB6124" class="hover pointer" @click="goVip()">加入VIP</span>,免费查看所有内容-->
          <!--</p>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
  name: "shopDetail",
  components: {Navbar},
  data() {
    return {
      id: '',//店铺id
      showPay: false,//显示支付
      activeName: '1',
      cates: [
        {id: '1',name: '建筑'},
        {id: '2',name: '五金'},
        {id: '3',name: '建材'},
        {id: '4',name: '化工'},
        {id: '5',name: '机电'},
        {id: '6',name: '机电'},
        {id: '7',name: '机电'},
        {id: '8',name: '机电'},
      ],//分类
      list: [null,null,null,null],//报价列表
      info: {
        id: '',
        name: '',
        address: '',
        logo_image: '',
        bg_image: [],
        info_address: '',
      },

      leftCurrent: 0,//当前选中的左侧分类
      leftCate: [
        {name: '行业分类'},{name: '建筑'},
      ],//左侧分类
      rightCurrent: 0,//当前选中的右侧分类
      rightGoods: [],//右侧商品
      showLoding: false,//显示加载
      limit: 12,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数

      timer: null,
      items: [],
      animate: false,
    }
  },
  methods: {
    //获取商家详情
    getShopDetail() {
      this.$axios("POST","/Mchs/getInfo",{id: this.id},'加载中')
      .then(res=>{
        if(res.code===1) {
          this.info = res.data
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    goPay() { //确认支付
      this.$router.push('/submitpay')
    },
    //留言
    goMessage() {
      let path = this.$router.resolve({
        path: '/message',
        query: {id: this.id}
      })
      window.open(path.href)
    },
    //选择左侧分类
    selectLeftCate(item,index) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = index
      item.check = true
      if(index===0) {
        this.getGoodsList()
      }
      return
      this.rightCurrent = 0
      this.page = 1
      this.getGoodsList()
    },
    //选择右侧分类
    selectRight(index,pindex) {
      this.leftCate.map(item => {
        item.check = false
        return item
      })
      this.leftCurrent = pindex
      this.rightCurrent = index
      this.rightGoods = 0
      this.page = 1
      this.getGoodsList()
    },
    //获取商城分类
    getGoodCate() {
      this.$axios("POST","/Mchs/getMchCate",{mch_id: this.id})
          .then(res => {
            if(res.code===1) {
              this.leftCate = res.data
              if(res.data.length>0) {
                this.getGoodsList();
              }
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //获取商品列表
    getGoodsList() {
      this.showLoding = true
      let data = {
        mch_id: this.id,
        // cate_id: this.leftCate[this.leftCurrent].san_list[this.rightCurrent].id,
        cate_id: '',
        page: this.page,
        limit: this.limit
      }
      this.$axios("POST","/Goods/getGoodList",data)
          .then(res=>{
            this.showLoding = false
            if(res.code===1) {
              this.rightGoods = res.data.list
              this.allPage = Math.ceil(res.data.total/this.limit)
            }else {
              this.$message.error(res.msg)
            }
          })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getGoodsList();
    },
    //商品详情
    goDetail(item) {
      let url = this.$router.resolve({
        path: '/goods',
        query: {
          id: item.id
        }
      })
      window.open(url.href)
    },
    //初始化动画
    initAnime() {
      this.$axios("POST","/common/getPostNewNotice",{})
          .then(res => {
            if(res.code==1) {
              for(let item of res.data) {
                this.items.push(item.text)
              }
              if(res.data.length>4) {
                this.timer=setInterval(this.scroll, 2000);
              }
            }
          })
    },
    // 开始滚动
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        this.items.push(this.items[0]);
        this.items.shift();
        this.animate = false; // margin-top 为0 的时候取消过渡动画
      }, 1500);
    },
    // 鼠标移入 暂停动画
    mouseOver(){
      clearInterval(this.timer)
    },
    // 鼠标移出 重新开始动画
    mouseOut(){
      this.timer=setInterval(this.scroll, 2000);
    }
  },
  created() {
    this.id = this.$route.query.id
    this.getShopDetail() //获取店铺详情
    // this.getGoodCate() //获取商城分类
    this.getGoodsList(); //获取商品列表
    this.initAnime() //初始化动画
    window.scrollTo({
      top: 0,//推荐使用，getBoundingClientRect 相对于当前视口的位置
      behavior: "smooth" // 平滑滚动
    });
  },

}
</script>

<style scoped>
.message_button{
  background-color: #3765FF;
  border-radius: 10px;
  padding: 8px 12px;
}
.shop_top{
  border: 1px solid #EEEEEE;
  padding-top: 11px;
  padding-bottom: 19px;
  width: 1200px;
  margin: 0 auto;
}
.logo{
  max-width: 140px;
  height: 60px;
  background: #1572F9;
  margin-right: 28px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}
.swiper{
  width: 100%;
}
/*分类*/
::v-deep .el-tabs__item{
  font-size: 32px;
  width: 200px;
  text-align: center;
}
::v-deep .el-tabs__nav-scroll{
  padding-bottom: 30px;
}
::v-deep .el-tabs__nav-next .el-icon-arrow-right{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
  font-size: 30px;
  padding-top: 5px;
}
::v-deep .el-tabs__item.is-active{
  color: #1572F9;
}
::v-deep .el-tabs__active-bar{
  bottom: -30px;
  height: 6px;
  background-color: #1572F9;
}
::v-deep .el-tabs__active-bar:after{
  content: '';
  width: 0;
  height: 0;
  border-bottom: 20px solid #1572F9;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,-100%);
}
::v-deep .el-tabs__nav-wrap::after{
  height: 6px;
}

.left_cate{
  box-sizing: border-box;
  padding: 18px 0;
  width: 150px;
  /*height: 888px;*/
  /*height: 620px;*/
  background: #FFFFFF;
  overflow-y: auto;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.left_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.left_item{
  width: 100%;
  min-height: 34px;
  box-sizing: border-box;
  padding: 8px 4px;
  font-size: 14px;
  color: #000000;
  transition: all 0.3s;
  cursor: pointer;
}
.left_item:hover{
  font-weight: bold;
}
.left_item.active{
  color: #ffffff;
  background-color: #0B8EFE;
}
/*.right_goods{
  margin-left: 18px;
  width: 1200px;
  height: 888px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  padding: 0 20px;
}*/
.right_goods::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
/*.right_cate{
  scrollbar-width: none; !* Firefox *!
  -ms-overflow-style: none; !* IE 10+ *!
  box-sizing: border-box;
  overflow-x: auto;
  padding: 28px 26px;
  white-space: nowrap;
}*/
.right_cate::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.right_item:nth-last-child(1) {
  margin-right: 0;
}
/*.right_item{
  display: inline-block;
  font-size: 28px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 90px;
}*/
.right_item.active{
  color: #1572F9;
}
/* .goods_item{
   width: 275px;
   height: 376px;
   background: #FFFFFF;
   box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
   border-radius: 4px 4px 4px 4px;
   margin-bottom: 25px;
   margin-right: 20px;
 }
 .goods_item:nth-child(4n) {
   margin-right: 0;
 }
 .goods_item .img{
   width: 275px;
   height: 241px;
   background: #f1f1f1;
   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
   border-radius: 4px 4px 4px 4px;
   overflow: hidden;
 }
 .goods_item .img img{
   transition: all 0.3s;
   width: 100%;
   height: 100%;
 }
 .goods_item:hover .img img{
   transform: scale(1.1);
 }*/
.home_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.right_goods{
  /*margin-left: 18px;*/
  /*width: 1030px;*/
  width: 1200px;
  min-height: 580px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 15px;
}
.right_cate{
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  box-sizing: border-box;
  overflow-x: auto;
  padding: 14px 26px;
  white-space: nowrap;
}
.right_item{
  font-size: 16px;
  color: #000000;
  transition: all 0.3s;
  margin-right: 20px;
}
.right_item:nth-child(5n) {
  margin-right: 0;
}
.goods_item{
  width: 185px;
  height: 260px;
  background: #FFFFFF;
  box-shadow: 0px 4px 6px 1px rgba(98, 128, 165, 0.3);
  border-radius: 4px 4px 4px 4px;
  margin-bottom: 15px;
  margin-right: 10px;
}
.goods_item:nth-child(6n) {
  margin-right: 0;
}
.goods_item .img{
  width: 185px;
  height: 160px;
  background: #f1f1f1;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px 4px 4px 4px;
  overflow: hidden;
}
.goods_item .img img{
  transition: all 0.3s;
  width: 100%;
  height: 100%;
}
.goods_item:hover .img img{
  transform: scale(1.1);
}

.home_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.home_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .number{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .more{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-next{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}
.buy_page ::v-deep .btn-prev{
  width: 40px;
  height: 40px;
  padding: 6px 5px;
}

.pay_bg{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 333;
  background-color: rgba(0,0,0,0.5);
}
.pay_warp{
  width: 602px;
  height: 389px;
  background: #FFFFFF;
  border-radius: 26px 26px 26px 26px;
  box-sizing: border-box;
  padding-top: 46px;
}
.button1{
  width: 216px;
  height: 73px;
  background: #DCDCDC;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
  border-radius: 40px;
  transition: all 0.2s;
}
.button1:hover{
  background-color: #c4c4c4;
}
.button2{
  border-radius: 40px;
  width: 216px;
  height: 73px;
  background: #1572F9;
  font-size: 36px;
  color: #ffffff;
  margin-left: 24px;
  cursor: pointer;
  transition: all 0.2s;
}
.button2:hover{
  background: #0152d0;
}

.swiper_warp{
  width: 1200px;
  margin: 0 auto;
}
.swiper{
  /*width: 100%;*/
  width: 900px;
}
.notice{
  width: 280px;
  height: 237px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 10px;
}
.newsList {
  margin: 0 auto;
  height: 187px;
  overflow: hidden;
}
.anim {
  transition: all 1.5s;
  margin-top: -48px;
}
#con1 li {
  color: #333333;
  list-style: none;
  cursor: pointer;
  transition: all 0.3s;
}
#con1 li:hover{
  text-decoration: none;
  color: #3765FF;
}
.news_item{
  width: 258px;
  position: relative;
  font-size: 14px;
  padding: 14px 0;
  position: relative;
  border-bottom: 1px solid #BFBFBF;
  padding-left: 23px;
}
.news_item:before{
  content: '';
  width: 8px;
  height: 8px;
  background: #1572F9;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.notice_border{
  width: 262px;
  height: 1px;
  border: 1px solid #2AA5E3;
  margin-top: 10px;
}
</style>
<style>
.popper1{
  border: 1px solid #1572F9 !important;
}
.popper1 .popper__arrow{
  border-right-color: #1572F9 !important;
}
</style>