<template>
  <div class="footer_warp difcac">
    <div class="mr0At fs14 colfff">
      <span v-if="config.name"> {{config.name}} |</span>
      <span v-if="config.beian"> {{config.beian}} |</span>
      <span v-if="config.address"> {{config.address}} |</span>
      <span v-if="config.postcode"> {{config.postcode}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottom_footer",
  data() {
    return {
      config: {
        name: '',//站点名称
        beian: '',//备案
        address: '',//公司地址
        postcode: '',//邮编
      },
    }
  },
  methods: {
    getConfig() { //获取系统配置
      this.$axios("POST","/index/getConfig",{})
          .then(res => {
            if(res.code===1) this.config = res.data
          })
    }
  },
  created() {
    this.getConfig()
  }
}
</script>

<style scoped>
  .footer_warp{
    width: 100%;
    height: 55px;
    background: #333333;
  }
</style>