import { render, staticRenderFns } from "./JobWantedDetail.vue?vue&type=template&id=686ea64f&scoped=true&"
import script from "./JobWantedDetail.vue?vue&type=script&lang=js&"
export * from "./JobWantedDetail.vue?vue&type=script&lang=js&"
import style0 from "./JobWantedDetail.vue?vue&type=style&index=0&id=686ea64f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686ea64f",
  null
  
)

export default component.exports