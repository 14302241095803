import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    imgUrl: 'https://web.dzhbzg.cn',//图片路径
    upLoadUrl: 'https://web.dzhbzg.cn/api/common/upload',//上传路径
    zIndex: 333,
    token: null,
    userInfo: {nickname:''},//用户信息
    code: '', //市级code
    address: '', //市级
  },
  mutations: {
    setAddress(state,payload) {
      state.address = payload.address
      state.code = payload.code
      localStorage.setItem('hb_address',payload.address)
      localStorage.setItem('hb_code',payload.code)
    },
    setIndex(state,payload) { //设置组件层级
      state.zIndex = payload.index
    },
    setToken(state,payload) { //设置token
      state.token = payload.token
    },
    setUserInfo(state,payload) { //设置用户信息
      state.userInfo = payload
    },
    logout(state) { //退出登录
      state.userInfo = {nickname:''}
      state.token = null
    },
  },
  actions: {

  },
  modules: {

  }
})
