<template>
  <div>
    <!--轮播图-->
    <div class="swiper_warp difb alc">
      <div class="swiper">
        <!--<el-carousel height="31vw">-->
        <el-carousel height="237px">
          <el-carousel-item v-for="item in swiper" :key="item.id">
            <img :src="item.slideimage" class="w100 h100 bgcb" @click="goSwiper(item)">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--新  公告-->
      <div class="notice">
        <!--公告-->
        <div class="difb alc" style="padding-left: 10px;padding-right: 32px;">
          <div class="dif alc">
            <img src="@/assets/images/icon_gonggao.png" style="width: 28px;height: 28px;margin-right: 5px;">
            <div class="fs20" style="color: #1572F9">最新成交</div>
          </div>
          <!--<img src="@/assets/images/icon_gengduo.png" style="width: 12px;height: 12px;">-->
        </div>
        <div class="notice_border"></div>
        <div class="newsList" @mouseover="mouseOver" @mouseout="mouseOut">
          <ul id="con1" ref="con1" :class="{ anim: animate }">
            <li v-for="(item, key) in items" class="news_item one_overflow">
              {{item}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--内容-->
    <div style="width: 1200px;margin: 30px auto;">
      <!--分类-->
      <div>
        <el-tabs v-model="activeName" @tab-click="changeTabs">
          <el-tab-pane v-for="(item,index) in cates" :key="index"
              :label="item.name" :name="item.id" ></el-tab-pane>
        </el-tabs>
      </div>
      <!--列表-->
      <div class="wtb_warp" v-if="list.length>0">
        <div class="wtb_item pointer" v-for="(item,index) in list" :key="index" @click="goDetail(item)">
          <div class="dif alc">
            <p class="one_overflow fs18 col000 hover" style="width: 510px;">{{item.title}}</p>
            <p style="margin-left: 191px;color: #FB6124" class="fs18 bold">{{item.status_text}}</p>
          </div>
          <div class="difb alc" style="margin-top: 35px;">
            <div class="fs14" style="color: #868686">{{item.userinfo.nickname}}</div>
            <div class="fs14" style="color: #868686">{{item.createtime_str}}</div>
          </div>
        </div>
      </div>
      <div v-else style="padding: 100px 0;">
        <el-result icon="info" title="提示" subTitle="暂无数据"></el-result>
      </div>
    </div>
    <!--分页-->
    <div class="difcac buy_page" style="margin: 38px 0 50px 0;">
      <el-pagination
          background
          layout="prev, pager, next"
          :page-count="allPage"
          :current-page="page"
          @current-change="changeSize">
      </el-pagination>
    </div>
    <!--支付提示-->
    <transition enter-active-class="animation-scale-up" leave-active-class="animation-scale-up reverse">
      <div class="pay_bg difcac" v-if="showPay" @click="showPay=false">
        <div class="pay_warp" @click.stop>
          <p class="tct fs32 col000">提示</p>
          <p class="fs32 tct" style="color: #414141;margin-top: 29px;">您需要消耗<span style="color: #F75C5C">{{show_pay_1}}元</span>，才可以继续浏览</p>
          <div class="difcac" style="margin-top: 56px;">
            <div class="button1 difcac" @click="showPay=false">取消</div>
            <div class="button2 difcac" @click="goPay()">确定</div>
          </div>
          <!--<p class="tct fs24 col000" style="margin-top: 42px;">-->
          <!--  <span style="color: #FB6124" class="hover pointer" @click="goVip()">加入VIP</span>,免费查看所有内容-->
          <!--</p>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Navbar from "@/components/common/navbar";
export default {
name: "WantToBuy",
  componentinsworkss: {Navbar},
  data() {
    return {
      show_pay_1: '获取中',//查看所需价格
      showPay: false,//显示支付
      activeName: 0,//当前分类
      cates: [],//分类
      list: [],//报价列表
      limit: 50,//每页数量
      page: 1,//当前页数
      allPage: 1,//总页数
      area_code: '370100',//城市code
      swiper: [],//轮播图
      timer: null,
      items: [],
      animate: false,

      setId: '',//当前选中的id
    }
  },
  methods: {
    //点击轮播图跳转
    goSwiper(item) {
      if(item.is_jump==2) {
        this.$router.push({
          path: '/shopDetail',
          query: {id: item.mch_id}
        })
      }
    },
    //获取查看所需金额
    getShowPay() {
      this.$axios("POST","/index/getConfig",{name: 'show_pay_1'})
      .then(res => {
        if(res.code===1) this.show_pay_1 = res.data
      })
    },
    //获取轮播图
    getSwiper() {
      this.$axios("POST","/index/getRotationMap",{module: 3})
          .then(res => {
            if(res.code===1) this.swiper = res.data
          })
    },
    goDetail(item) { //跳转到详情
      if(item.is_jump==0) { //投标人数已满
        return this.$alert('投标人数已满',{type: 'info'})
      }
      if(item.is_pay==1) { //需要支付
        this.setId = item.id
        this.show_pay_1 = item.pay_amount
        return this.showPay = true
      }
      //跳转到详情
      this.$router.push({
        path: '/wangtobuy/detail',
        query: {id: item.id},
      })
    },
    goPay() { //确认支付
      this.$router.push({
        path: '/submitPay',
        query: {
          showType: 1,
          id: this.setId,
          type: 1,
          path: this.$route.path,
          page: this.page,
          pay_amount: this.show_pay_1
        }
      })
    },
    goVip() { //开通会员
      this.$router.push('/me/vip')
    },
    //获取工程设备求购列表
    getList() {
      let industry_id = this.activeName
      if(this.activeName=='123456') industry_id = ''
      this.$axios("POST","/post/ask_buy/getList",{
        page: this.page,
        limit: this.limit,
        area_code: this.$store.state.code,
        industry_id: industry_id,
      })
      .then(res => {
        if(res.code===1) {
          this.list = res.data.list
          this.allPage = Math.ceil(res.data.total/this.limit)
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    //切换页数
    changeSize(e) {
      this.page = e
      this.getList();//获取工程设备求购列表
    },
    //获取行业分类
    getIndustry() {
      this.$axios("POST","/index/getIndustry",{})
      .then(res => {
        if(res.code===1) {
          for(let item of res.data) {
            item.id = item.id+''
          }
          this.cates = res.data
          this.cates.unshift({id: '123456',name: '全部'})
          if(this.cates.length>0) {
            this.activeName = res.data[0].id+''
            this.getList() //获取列表
          }
        }else {
          this.$alert(res.msg,{type: 'error'})
        }
      })
    },
    //获取定位
    getLocation() {
      var  geolocation = new AMap.Geolocation({
        enableHighAccuracy: true,//是否使用高精度定位，默认:true
        timeout: 100000,
        maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        needAddress: true,
        extensions: 'all'
      });
      let that = this;
      geolocation.getCityInfo(function (status,res) {
        // 逆地理编码
        if(status==='complete' && res.adcode) {
          console.log('成功获取');
          that.area_code = res.adcode.substring(0, 4)+'00' //市级编码
          let geocoder = new AMap.Geocoder({
            extensions: 'all'
          })
          let lnglat = res.position
          geocoder.getAddress(lnglat,function (code,res1) {
            console.log(res1);
            if (code === 'complete' && res1.info === 'OK') {
              let data = res1.regeocode.addressComponent
              that.area_code = data.adcode.substring(0, 4)+'00' //市级编码
              that.getIndustry() //获取分类
            }else {
              that.getIndustry() //获取分类
            }
          })
        }else {
          console.log('失败');
          that.getIndustry() //获取分类
        }

      });
    },
    changeTabs(tab,event) {
      this.activeName = tab.name
      this.page  =1
      this.getList()
    },
    //初始化动画
    initAnime() {
      this.$axios("POST","/common/getPostNewNotice",{})
          .then(res => {
            if(res.code==1) {
              for(let item of res.data) {
                this.items.push(item.text)
              }
              if(res.data.length>4) {
                this.timer=setInterval(this.scroll, 2000);
              }
            }
          })
    },
    // 开始滚动
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      setTimeout(() => {
        this.items.push(this.items[0]);
        this.items.shift();
        this.animate = false; // margin-top 为0 的时候取消过渡动画
      }, 1500);
    },
    // 鼠标移入 暂停动画
    mouseOver(){
      clearInterval(this.timer)
    },
    // 鼠标移出 重新开始动画
    mouseOut(){
      this.timer=setInterval(this.scroll, 2000);
    }
  },
  created() {
    eventBus.$on('updateList',()=>{
      console.log('HashChangeEvent');
      this.changeSize(1)
    })
    if(this.$route.query.page) this.page = Number(this.$route.query.page)
    this.initAnime() //开始滚动动画
    this.getSwiper() //获取轮播图
    // this.getList()
    this.getLocation() //获取定位
    this.getShowPay() //获取查看所需金额
  }
}
</script>

<style scoped>

  /*分类*/
  ::v-deep .el-tabs__item{
    font-size: 18px !important;
    width: 140px !important;
    padding: 0 !important;
    text-align: center;
  }
  ::v-deep .el-tabs__nav-wrap.is-scrollable{
    padding: 0 33px;
  }
  ::v-deep .el-tabs__nav-scroll{
    padding-bottom: 20px !important;
  }
  ::v-deep .el-tabs__nav-next .el-icon-arrow-right{
    font-size: 18px;
    padding-top: 5px;
    font-weight: bold;
  }
  ::v-deep .el-tabs__nav-prev .el-icon-arrow-left{
    font-size: 18px;
    padding-top: 5px;
    font-weight: bold;
  }
  ::v-deep .el-tabs__item.is-active{
    color: #1572F9;
  }
  ::v-deep .el-tabs__active-bar{
    bottom: -20px !important;
    height: 6px;
    background-color: #1572F9;
    width: 140px !important;
  }
  ::v-deep .el-tabs__active-bar:after{
    content: '';
    width: 0;
    height: 0;
    border-bottom: 20px solid #1572F9;
    border-right: 20px solid transparent;
    border-left: 20px solid transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-100%);
  }
  ::v-deep .el-tabs__nav-wrap::after{
    height: 6px;
  }
  .wtb_warp{
    box-sizing: border-box;
    padding: 0 23px;
  }
  .wtb_item{
    border-bottom: 1px solid #F2F2F2;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .buy_page ::v-deep .number{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }
  .buy_page ::v-deep .more{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }
  .buy_page ::v-deep .btn-next{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }insworks
  .buy_page ::v-deep .btn-prev{
    width: 40px;
    height: 40px;
    padding: 6px 5px;
  }

  .pay_bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 333;
    background-color: rgba(0,0,0,0.5);
  }
  .pay_warp{
    width: 602px;
    height: 389px;
    background: #FFFFFF;
    border-radius: 26px 26px 26px 26px;
    box-sizing: border-box;
    padding-top: 46px;
  }
  .button1{
    width: 216px;
    height: 73px;
    background: #DCDCDC;
    font-size: 36px;
    color: #ffffff;
    cursor: pointer;
    border-radius: 40px;
    transition: all 0.2s;
  }
  .button1:hover{
    background-color: #c4c4c4;
  }
  .button2{
    border-radius: 40px;
    width: 216px;
    height: 73px;
    background: #1572F9;
    font-size: 36px;
    color: #ffffff;
    margin-left: 24px;
    cursor: pointer;
    transition: all 0.2s;
  }
  .button2:hover{
    background: #0152d0;
  }

  .swiper_warp{
    width: 1200px;
    margin: 0 auto;
  }
  .swiper{
    /*width: 100%;*/
    width: 900px;
  }
  .notice{
    width: 280px;
    height: 237px;
    background: #FFFFFF;
    box-sizing: border-box;
    padding-top: 10px;
  }
  .newsList {
    margin: 0 auto;
    height: 187px;
    overflow: hidden;
  }
  .anim {
    transition: all 1.5s;
    margin-top: -48px;
  }
  #con1 li {
    color: #333333;
    list-style: none;
    cursor: pointer;
    transition: all 0.3s;
  }
  #con1 li:hover{
    text-decoration: none;
    color: #3765FF;
  }
  .news_item{
    width: 258px;
    position: relative;
    font-size: 14px;
    padding: 14px 0;
    position: relative;
    border-bottom: 1px solid #BFBFBF;
    padding-left: 23px;
  }
  .news_item:before{
    content: '';
    width: 8px;
    height: 8px;
    background: #1572F9;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .notice_border{
    width: 262px;
    height: 1px;
    border: 1px solid #2AA5E3;
    margin-top: 10px;
  }
</style>