<template>
  <div>
    <navbar v-if="path!=='/login'"></navbar>
    <keep-alive>
      <router-view v-if="path==='/'"></router-view>
    </keep-alive>
    <router-view v-if="path!=='/'" style="min-height: 90vh"></router-view>
    <!--<keep-alive>-->
    <!--  <router-view></router-view>-->
    <!--</keep-alive>-->
    <bottom_footer v-if="path!=='/login'"></bottom_footer>
    <el-backtop :right="40" :bottom="40"></el-backtop>
  </div>
</template>

<script>
import Bottom_footer from "@/components/common/bottom_footer";
import Navbar from "@/components/common/navbar";
export default {
  name: 'app',
  components: {
    Navbar,
    Bottom_footer
  },
  data() {
    return {
      path: '',//当前路由2
    }
  },
  methods: {
    setTitle(arr) {
      let list = []
      for(let item of arr) {
        if(item.meta.title) list.push(item.meta.title)
      }
      document.title = list.pop()
    },
  },
  watch: {
    $route(newValue) {
      this.path = newValue.path
      this.setTitle(newValue.matched)
    }
  },
  created() {
    this.path = this.$route.path
    let token = localStorage.getItem('hbzg')
    let uInfo = localStorage.getItem('hbInfo')
    this.setTitle(this.$route.matched)
    if(uInfo) this.$store.commit('setUserInfo',JSON.parse(uInfo))
    if(token) {
      this.$store.commit('setToken',token)
    }
  },
  mounted() {
    window.addEventListener("storage", ev => {
      if(ev.key === 'hbzg'){
        // 业务处理逻辑
        console.log(ev);
        if(!ev.newValue) {
          localStorage.removeItem('hbzg')
          localStorage.removeItem('hbInfo')
          this.$store.commit('logout')
          this.$router.push({
            path: '/login',
            query: {type: 0}
          })
        }
      }
    })
  }
}
</script>

<style>
  html{
    font-size:16px;
  }
</style>
